var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const PeopleTwo = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M18.5 19.5a2 2 0 0 0 2-2v-2a4 4 0 0 0-4-4h-1.04c-.47.005-.508.616-.121.883A4.995 4.995 0 0 1 17.5 16.5V19a.5.5 0 0 0 .5.5h.5ZM14 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM4 17a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-3Zm11.39-7.002c-.327-.012-.484-.372-.357-.674.3-.715.467-1.5.467-2.324a5.98 5.98 0 0 0-.467-2.324c-.127-.302.03-.662.357-.674L15.5 4a3 3 0 1 1-.11 5.998Z" }) }) })));
};
