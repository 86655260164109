import React from 'react';
import { createRoot } from 'react-dom/client';
import { initTrackers } from './config/trackers';
import ErrorBoundary from './components/ErrorBoundary';
import App from './App';

import './index.scss';

import '@ruter-ds-next/button/index.css';
import '@ruter-ds-next/checkbox/index.css';
import '@ruter-ds-next/input/index.css';
import '@ruter-ds-next/radio/index.css';
import '@ruter-ds-next/switch/index.css';

initTrackers();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);
