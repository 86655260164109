var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Train = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M11.228 3.177a.25.25 0 0 1 .354 0l1.89 1.89a.5.5 0 0 1 0 .707l-1.23 1.23h7.099a1 1 0 0 1 .919.607l2.473 5.767a1 1 0 0 1-.21 1.1l-1.23 1.233a1 1 0 0 1-.708.294H2a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h8.827l1.584-1.584-1.537-1.537a.25.25 0 0 1 0-.354l.354-.353Zm7.886 6.165A.5.5 0 0 0 18.64 9H15v3h4.653a.25.25 0 0 0 .237-.329l-.776-2.33Z", fillRule: "evenodd" }), _jsx("path", { d: "M1 17.25a.25.25 0 0 1 .25-.25h21.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25H1.25a.25.25 0 0 1-.25-.25v-.5Z" })] }) })));
};
