var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const CabinAlt = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M6.969 5.75a2 2 0 1 0-1.938 0 1.001 1.001 0 1 0 1.938 0Z" }), _jsx("path", { d: "M19.427 5.427A.25.25 0 0 1 19.604 5h.189a.5.5 0 0 0 .353-.854l-1.793-1.792a.5.5 0 0 0-.707 0l-1.793 1.792a.5.5 0 0 0 .354.854h.19a.25.25 0 0 1 .176.427l-1.72 1.72a.5.5 0 0 0 .354.853h.19a.25.25 0 0 1 .176.427l-1.72 1.72a.5.5 0 0 0 .354.853H16.5a.5.5 0 0 1 .5.5v.5h-3.5l-.31-1.242a1 1 0 0 0-.97-.758H7V8.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V10H3.78a1 1 0 0 0-.97.758l-.655 2.62A.5.5 0 0 0 2.64 14h.86a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H2.25a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h19.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25H20.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h.86a.5.5 0 0 0 .485-.621l-.656-2.621a1 1 0 0 0-.97-.758H19v-.5a.5.5 0 0 1 .5-.5h2.293a.5.5 0 0 0 .353-.854l-1.72-1.72A.25.25 0 0 1 20.605 8h.189a.5.5 0 0 0 .353-.854l-1.72-1.72ZM19 20v-2.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V20h5ZM8 20v-2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V20h2Zm1-5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Z", fillRule: "evenodd" })] }) })));
};
