import React from 'react';
import { House, Bus, Tram, Boat, Metro, Train, Poi } from '@ruter-ds-next/icons'


type CustomStyles = Record<string, string | number>;

type GetIconWithoutCircle = (
  id: string,
  className?: string,
  customStyles?: CustomStyles,
  key?: string
) => React.ReactNode;
export const getIconWithoutCircle: GetIconWithoutCircle = (id, className = '', customStyles = {}, key) => {
  const style = {
    height: '1.5em',
    width: 'auto',
    fill: '#FFF',
    ...customStyles,
  };
  switch (id) {
    case 'bus':
      return <Bus style={style} className={className} key={key} />;
    case 'metro':
      return <Metro style={style} className={className} key={key} />;
    case 'water':
      return <Boat style={style} className={className} key={key} />;
    case 'tram':
      return <Tram style={style} className={className} key={key} />;
    case 'rail':
      return <Train style={style} className={className} key={key} />;
    case 'home':
      return <House style={style} className={className} key={key} />;
    case 'poi':
      return <Poi style={style} className={className} key={key} />;
    default:
      return null;
  }
};
