import React, { useState } from 'react';
import { ExclamationTriangle } from '@ruter-ds-next/icons'
import Header from '../../shared/components/Header';
import StopPlaceSearch from '../StopPlaceSearch';
import NavigateButton from '../NavigateButton';
import { getStopPlacesByCoords } from '../../shared/data/graphql';
import { LogoColor } from '../../shared/components/Icons/Logo';

import './styles.scss';

const LandingPage = () => {
  const [location, setLocation] = useState<Mon.Coordinates | null>(null);
  const [noStopsForMyPos, setNoStopsForMyPos] = useState<boolean>(false);

  const handleSelectItem = (coordinates: Mon.Coordinates | null) => {
    if (coordinates) {
      const { latitude, longitude } = coordinates;
      getStopPlacesByCoords(latitude, longitude).then((stopPlaces) => {
        if (!stopPlaces.length) {
          setNoStopsForMyPos(true);
          setLocation(null);
        } else {
          setLocation(coordinates);
          setNoStopsForMyPos(false);
        }
      });
    } else {
      setNoStopsForMyPos(false);
      setLocation(coordinates);
    }
  };

  return (
    <div className="landingPage">
      <Header logoColor={LogoColor.red} navigateToSettings={false} showClock={false} />
      <div className="description">Her kan du opprette din egen avgangsoversikt – på én eller flere tavler</div>
      <div className="searchWrapper">
        <div className="searchWrapperColumn">
          <div>Søk etter områder du vil vise avganger fra</div>
          <div className="searchWrapperRow">
            <StopPlaceSearch handleSelectItem={handleSelectItem} />
            <NavigateButton location={location} />
          </div>
        </div>
      </div>
      {noStopsForMyPos ? (
        <div className="warningWrapper">
          <p>
            <h3>
              <ExclamationTriangle className="inline-block" />
              Vi fant ingen stoppesteder i nærheten av deg
            </h3>
            <p>Søk opp en adresse eller stedsnavn i nærheten av deg du vil vise trafikk fra.</p>
          </p>
        </div>
      ) : null}
      <div className="privacyContent">
        Les om hvordan vi behandler personvernet ditt{' '}
        <a href="/privacy" target="_blank">
          her
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
