var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const VolumeUp = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M9.586 19.586c1.26 1.26 3.414.367 3.414-1.414V5.828c0-1.781-2.154-2.674-3.414-1.414L6.293 7.707A1 1 0 0 1 5.586 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h1.586a1 1 0 0 1 .707.293l3.293 3.293Zm8.86-16.78a.483.483 0 0 0-.729.14l-.505.883a.518.518 0 0 0 .116.645A9.95 9.95 0 0 1 20.76 12a9.95 9.95 0 0 1-3.43 7.524.518.518 0 0 0-.114.649l.51.877c.15.26.496.33.727.137 2.627-2.2 4.307-5.5 4.307-9.187 0-3.686-1.68-6.986-4.314-9.194Z" }), _jsx("path", { d: "M15.289 7.12a.525.525 0 0 1-.089-.628l.525-.91a.476.476 0 0 1 .739-.12A8.947 8.947 0 0 1 19.29 12a8.95 8.95 0 0 1-2.826 6.54.476.476 0 0 1-.74-.122l-.524-.91a.525.525 0 0 1 .089-.627 6.973 6.973 0 0 0 2.001-4.88c0-1.897-.769-3.62-2.001-4.882Z" })] }) })));
};
