var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Snail = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M6.84 15.88a.741.741 0 0 0-.555-.066l-.975.293a4 4 0 0 0-2.105 1.506l-1.423 1.992a.25.25 0 0 0 .204.395h13.13a6 6 0 0 0 4.686-2.252l.883-1.105A6 6 0 0 0 22 12.895V4.75a.25.25 0 0 0-.25-.25h-.545a.25.25 0 0 0-.245.201l-.38 1.897a.5.5 0 0 1-.49.402h-.18a.5.5 0 0 1-.49-.402l-.38-1.897a.25.25 0 0 0-.245-.201h-.545a.25.25 0 0 0-.25.25V9c0 .5.018.763-.037 1.156-.719 5.215-6.46 8.165-11.123 5.723Z" }), _jsx("path", { d: "M8.307 15.208a6.48 6.48 0 0 1-.91-.392A2.419 2.419 0 0 1 6.1 12.685V7.5a1.4 1.4 0 0 1 1.4-1.4h5.91c2.057 0 3.647 1.843 3.365 3.892a7.537 7.537 0 0 1-.007.048c-.586 4.028-4.746 6.439-8.461 5.168Z" })] }) })));
};
