var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Cabin = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M14 7a2 2 0 1 0-1.112-3.663 2 2 0 0 0-3.711-.16A2 2 0 0 0 10 7h4Z" }), _jsx("path", { d: "M19.9 11.374a.23.23 0 0 1 .179-.374.46.46 0 0 0 .368-.737L18.4 7.533a.5.5 0 0 0-.8 0l-2.048 2.73a.46.46 0 0 0 .369.737c.193 0 .3.223.18.374l-1.451 1.814a.5.5 0 0 0 .39.812h.356a.25.25 0 0 1 .177.427l-1.72 1.72a.5.5 0 0 0 .354.853H16.5a.5.5 0 0 1 .5.5V20h-4.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h.86a.5.5 0 0 0 .485-.621l-.656-2.621a1 1 0 0 0-.97-.758H7V8.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V10H3.78a1 1 0 0 0-.97.758l-.655 2.62A.5.5 0 0 0 2.64 14h.86a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H2.25a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h19.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25H19v-2.5a.5.5 0 0 1 .5-.5h2.293a.5.5 0 0 0 .353-.854l-1.72-1.72a.25.25 0 0 1 .178-.426h.356a.5.5 0 0 0 .39-.812l-1.45-1.814ZM9 14.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM6.5 17a.5.5 0 0 0-.5.5V20h2v-2.5a.5.5 0 0 0-.5-.5h-1Z", fillRule: "evenodd" })] }) })));
};
