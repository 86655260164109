import React from 'react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}
const MaximizeIcon = ({ className = '', style = {} }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M20.7195 20.7135V15.8738H22.5V22.4977H15.8738V20.7135H20.7195ZM20.7135 3.2805H15.8738V1.5H22.4977V8.12625H20.7135V3.2805ZM3.2805 3.28425V8.124H1.5V1.5H8.12625V3.28425H3.2805ZM3.28425 20.7195H8.124V22.5H1.5V15.8738H3.28425V20.7195Z"
        fill="#fff"
      />
    </svg>
  );
};

export default MaximizeIcon;
