import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@ruter-ds-next/button';

interface Props {
  location?: Mon.Coordinates;
}
const NavigateButton = ({ location }: Props) => {
  const navigate = useNavigate();

  const navigateToBoards = (e, coordinates: Mon.Coordinates) => {
    e.preventDefault();

    if (coordinates) {
      const position = `${coordinates.latitude}-${coordinates.longitude}`;
      navigate(`/departures/${position}`);
    }
  };

  return (
    <Button
      skin="PRIMARY"
      type="submit"
      className="searchButton"
      disabled={!location}
      onClick={(e) => {
        if (location) {
          navigateToBoards(e, location);
        }
      }}
    >Opprett tavle</Button>
  );
};

export default NavigateButton;
