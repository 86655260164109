var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const HeartOutline = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M12.336 9.397a.5.5 0 0 1-.672 0L8.736 6.735a2.89 2.89 0 0 0-3.99 4.183l7.284 7.283 7.153-6.854c1.145-1.098 1.23-2.932.175-4.149-1.112-1.283-3.064-1.398-4.301-.274l-2.72 2.473ZM12 7l-1.92-1.745a4.89 4.89 0 0 0-6.747 7.078l7.975 7.975a1 1 0 0 0 1.399.015l7.86-7.532c1.923-1.844 2.048-4.89.303-6.903-1.84-2.122-5.08-2.333-7.159-.444L12 7Z", fillRule: "evenodd" }) }) })));
};
