import React from 'react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}
const MinimizeIcon = ({ className = '', style = {} }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M17.169 17.1735V22.4977H15.2115V15.2115H22.5V17.1735H17.169ZM17.1735 6.831H22.4977V8.7885H15.2115V1.5H17.1735V6.831ZM6.831 6.8235V1.5H8.7885V8.78625H1.5V6.825L6.831 6.8235ZM6.8235 17.169H1.5V15.2115H8.78625V22.5H6.825L6.8235 17.169Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MinimizeIcon;
