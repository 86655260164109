import React from 'react';

import './styles.scss';

const UnableToFetch = () => {
  return (
    <div className="unableToFetch">
      <span className="title">Ops, det ser ut som tjenesten er nede!</span>
      <span className="description">Tjenesten ser ut til å være midlertidig nede.</span>
      <span className="description">Vi jobber med saken, og beklager ulempene!</span>
    </div>
  );
};

export default UnableToFetch;
