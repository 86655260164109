var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Cat = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M5.707 4.293A1 1 0 0 1 6.414 4H7V2.405a.25.25 0 0 1 .362-.224l.156.078a4 4 0 0 1 2.006 2.313l1.129 3.386a2 2 0 0 0 1.412 1.308l1.328.332a4 4 0 0 1 2.46 1.823l1.442 2.404a4 4 0 0 1 .542 2.535l-.343 2.856a.25.25 0 0 0 .434.197l2.2-2.445a.5.5 0 0 1 .872.335V19a3 3 0 0 1-3 3H3.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h.806a.5.5 0 0 0 .475-.342l.957-2.872a2 2 0 0 0-.108-1.527l-1.11-2.22a2 2 0 0 1 .124-2.005l.838-1.257A.5.5 0 0 0 5.066 9h-.238a2 2 0 0 1-1.414-.586l-.121-.121A1 1 0 0 1 3 7.586v-1.04c0-.3.245-.546.547-.546.29 0 .568-.115.773-.32l1.387-1.387ZM7.347 20a.25.25 0 0 1-.237-.33l.653-1.959a.25.25 0 0 1 .474 0l.653 1.96a.25.25 0 0 1-.237.329H7.347Z", fillRule: "evenodd" }) }) })));
};
