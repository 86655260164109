var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const ScooterPersonAlt = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M8.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" }), _jsx("path", { d: "M15 8.5a.5.5 0 0 1 .5-.5H18a1 1 0 0 1 .976.783l1.754 7.89a.572.572 0 0 0 .423.413 2.501 2.501 0 1 1-2.268.506l-.081-.366L15.449 21H5.75a.563.563 0 0 0-.41.192A2.5 2.5 0 1 1 5.95 19h.75l.787-5.507a2 2 0 0 0-.007-.612l-.72-4.327C6.47 6.827 8.384 5.59 9.84 6.56l1.662 1.108h2.046a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2.652a1 1 0 0 1-.554-.168l-.245-.164.468 2.814-.021.152 1.287-.858a.5.5 0 0 1 .693.139l1.6 2.4a2 2 0 0 1 .277 1.594L13.796 19h.755l3.382-3.76a1 1 0 0 0 .233-.885L17.198 10H15.5a.5.5 0 0 1-.5-.5v-1Zm-4.657 6.218L9.73 19h.973l.68-2.72-1.041-1.562ZM3.5 20.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm17 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z", fillRule: "evenodd" })] }) })));
};
