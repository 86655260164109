var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Scooter = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M13 4.5a.5.5 0 0 1 .5-.5H17a1 1 0 0 1 .97.757L20.7 15.68a.574.574 0 0 0 .426.399 2.501 2.501 0 1 1-2.256.525l-.16-.64-2.93 3.66A1 1 0 0 1 15 20H5.75a.563.563 0 0 0-.41.192A2.5 2.5 0 1 1 5.95 18h8.089a1 1 0 0 0 .78-.375l2.972-3.714a1 1 0 0 0 .19-.868L16.218 6H13.5a.5.5 0 0 1-.5-.5v-1Zm-9.5 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm17 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z", fillRule: "evenodd" }), _jsx("path", { d: "M2.5 13a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H5a2 2 0 0 1 1.938 1.505c.068.268.286.495.562.495h1c.276 0 .503-.225.47-.499A4 4 0 0 0 5 13H2.5Z" })] }) })));
};
