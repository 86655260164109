var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const QrCode = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M14 10v2h-2v-2h2Z" }), _jsx("path", { d: "M4 2a2 2 0 0 0-2 2v8h2v2H2v6a2 2 0 0 0 2 2h6v-4h2v4h4v-2h-2v-2h2v-2h-2v-2h2v-2h2v4h2v2h-2v4h1a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-3h-2v-2h2v-2h-4v-2h4V4a2 2 0 0 0-2-2h-6v4h-2V4h-2V2H4Zm8 6V6h-2v2h2Zm2 2V8h-2v2H8v2H6v-2H4v2h2v2h2v-2h4v2h-2v2h2v2h2v-2h-2v-2h2v-2h2v-2h-2ZM8 20v-4H4v4h4Zm8-16h4v4h-4V4Z", fillRule: "evenodd" })] }) })));
};
