var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Boat = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M8.99 15.002c-1.95 0-2.98.44-3.89.83-.576.249-1.098.474-1.872.59-.348.052-.608-.29-.496-.624l1.154-3.455a.5.5 0 0 1 .474-.341h1.28a.5.5 0 0 0 .474-.342l1.328-3.975a1 1 0 0 1 .948-.683H12V6.31a.5.5 0 0 1 .276-.448l3-1.5A.5.5 0 0 1 16 4.81V6h1.28a1 1 0 0 1 .948.684l1.544 4.633a1 1 0 0 0 .949.684h1.585a.5.5 0 0 1 .474.658l-.696 2.082a.517.517 0 0 1-.429.345c-1.133.142-1.878.452-2.555.745l-.005.002c-.838.359-1.558.668-3.105.668s-2.267-.309-3.105-.668l-.005-.002c-.9-.39-1.93-.83-3.89-.83Zm8.316-3a.5.5 0 0 0 .475-.658l-.667-2a.5.5 0 0 0-.474-.342H15v3h2.306Zm-3.306-3H9.36a.5.5 0 0 0-.474.342l-.667 2a.5.5 0 0 0 .475.658H14v-3Z", fillRule: "evenodd" }), _jsx("path", { d: "m19.095 17.834.005-.002c.41-.178.843-.36 1.375-.509.364-.101.67.252.55.61l-.42 1.254a.514.514 0 0 1-.295.312c-.076.03-.152.062-.229.094l-.191.079c-.91.39-1.94.83-3.9.83-1.95 0-2.98-.44-3.89-.83-.84-.36-1.57-.67-3.11-.67-1.537 0-2.257.308-3.095.667l-.005.003c-.91.39-1.94.83-3.89.83-.062 0-.122 0-.181-.002a.475.475 0 0 1-.436-.635l.343-1.022a.518.518 0 0 1 .474-.348 7.94 7.94 0 0 0 2.9-.663c.91-.39 1.94-.83 3.89-.83 1.96 0 2.99.44 3.89.83.84.36 1.57.67 3.11.67 1.547 0 2.267-.309 3.105-.668Z" })] }) })));
};
