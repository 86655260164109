var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Store = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M8 7a2 2 0 1 0-1.112-3.663 2 2 0 0 0-3.711-.16A2 2 0 0 0 4 7h4Zm12.228 1.684A1 1 0 0 0 19.279 8h-8.558a1 1 0 0 0-.949.684l-.67 2.008c-.067.204-.119.423-.05.627A1 1 0 0 0 11 11a1 1 0 0 0 2 0 1 1 0 0 0 2 0 1 1 0 0 0 2 0 1 1 0 0 0 2 0 1 1 0 0 0 1.948.319c.069-.204.017-.423-.05-.627l-.67-2.008Z" }), _jsx("path", { d: "M2.25 20a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h19.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25H20v-6.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V20H8v-4.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V20H2.25ZM5 20h2v-1H5v1Zm9-2.5V20h-2v-2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5Zm2.5-2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z", fillRule: "evenodd" })] }) })));
};
