var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const CarSharing = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M15.756 10c.535 0 1.002.344 1.132.833l.654 2.467h1.166c.161 0 .292.123.292.275v.55c0 .152-.13.275-.292.275h-.875c.645 0 1.167.492 1.167 1.1v3.85c0 .304-.261.55-.583.55h-.584v.55c0 .304-.26.55-.583.55h-1.167c-.322 0-.583-.246-.583-.55v-.55h-7v.55c0 .304-.261.55-.583.55H6.75c-.322 0-.583-.246-.583-.55v-.55h-.584c-.322 0-.583-.246-.583-.55V15.5c0-.607.522-1.1 1.167-1.1h-.875c-.161 0-.292-.123-.292-.275v-.55c0-.152.13-.275.292-.275h1.166l.654-2.467c.13-.49.597-.833 1.132-.833h7.512Zm-7.84 7.7c.323 0 .584-.246.584-.55v-1.1c0-.304-.261-.55-.583-.55H6.75c-.322 0-.583.246-.583.55v1.1c0 .304.26.55.583.55h1.167Zm9.334-2.2c.322 0 .583.246.583.55v1.1c0 .304-.26.55-.583.55h-1.167c-.322 0-.583-.246-.583-.55v-1.1c0-.304.261-.55.583-.55h1.167Zm-9.19-4.185a.288.288 0 0 1 .285-.215h7.31a.29.29 0 0 1 .285.215l.648 2.75c.04.172-.098.335-.285.335H7.697c-.187 0-.325-.163-.285-.335l.648-2.75ZM7 4.5a1 1 0 0 1 1-1h2A2 2 0 0 1 11.937 5h4.356a.5.5 0 0 1 .353.146l.677.677a.25.25 0 0 1 0 .354l-.78.78a.15.15 0 0 1-.105.043h-.288a.15.15 0 0 1-.15-.15v-.2a.15.15 0 0 0-.15-.15h-.2a.15.15 0 0 0-.15.15v.2a.15.15 0 0 1-.15.15h-.2a.15.15 0 0 1-.15-.15v-.7a.15.15 0 0 0-.15-.15h-.2a.15.15 0 0 0-.15.15v.7a.15.15 0 0 1-.15.15h-.2a.15.15 0 0 1-.15-.15v-.2a.15.15 0 0 0-.15-.15h-.2a.15.15 0 0 0-.15.15v.2a.15.15 0 0 1-.15.15h-1.413A2 2 0 0 1 10 8.5H8a1 1 0 0 1-1-1v-3Zm1 1.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 6.25v-.5Z", fillRule: "evenodd" }) }) })));
};
