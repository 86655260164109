import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { FILTERED_BOARD_REASONS, getFilteredBoardsVerbose } from '../../shared/utils/boardSettings';
import { getPathName } from '../../shared/utils/URL';

import './styles.scss';

const buildSentence = (reason: string, index: number, numberOfReasons: number) => {
  if (numberOfReasons === 1) {
    return `${reason}.`;
  }

  if (index === numberOfReasons - 1) {
    return `, og ${reason}.`;
  }

  return reason;
};

const createHumanReadableReason = (reasons: string[] = []) => {
  let reasonString = '';

  reasons.forEach((reason, index) => {
    const numberOfReasons = reasons.length;
    if (reason === FILTERED_BOARD_REASONS.BOARD_DISABLED) {
      reasonString += buildSentence('tavla er skjult', index, numberOfReasons);
    } else if (reason === FILTERED_BOARD_REASONS.ZERO_STOP_PLACES) {
      reasonString += buildSentence('tavla har ingen stoppesteder', index, numberOfReasons);
    } else if (reason === FILTERED_BOARD_REASONS.ZERO_NoD) {
      reasonString += buildSentence('tavla viser ingen avganger', index, numberOfReasons);
    } else {
      console.log('REASON NOT FOUND', reason, numberOfReasons);
    }
  });
  return reasonString;
};

interface Props {
  boardsConfig: Mon.BoardConfig[];
}
const NoDepartures = ({ boardsConfig = [] }: Props) => {
  const { reasons } = getFilteredBoardsVerbose(boardsConfig);
  const navigate = useNavigate();
  const navigateToSettings = (e) => {
    const url = getPathName('settings');
    navigate(url);
    e.preventDefault();
  };

  if (!boardsConfig.length) {
    return null;
  }

  return (
    <div className="noDepartures">
      <span className="title">Ojda, det er ingen avganger å vise!</span>
      {Array.isArray(reasons) && reasons.length === 2 ? (
        <Fragment>
          <span className="guidance-0">Det kan skyldes én av følgende grunner:</span>
          <span className="guidance-1">Tavle 1: {createHumanReadableReason(reasons[0])}</span>
          <span className="guidance-2">Tavle 2: {createHumanReadableReason(reasons[1])}</span>
          <span className="guidance">
            Trykk{' '}
            <span onClick={navigateToSettings} className="link">
              her
            </span>{' '}
            for å endre dine innstillinger.
          </span>
        </Fragment>
      ) : null}
    </div>
  );
};

export default NoDepartures;
