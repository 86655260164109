var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const BikePerson = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M15 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" }), _jsx("path", { d: "M5 22a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm13.724-5.99a4 4 0 1 1-1.898.632l-.617-1.852a.5.5 0 0 1 .317-.632l.948-.316a.5.5 0 0 1 .633.316l.617 1.851ZM21 18a2 2 0 1 1-3.496-1.327l.39 1.169a.5.5 0 0 0 .632.316l.948-.316a.5.5 0 0 0 .317-.633l-.39-1.169A2 2 0 0 1 21 18Z", fillRule: "evenodd" }), _jsx("path", { d: "M9.487 6.607a2 2 0 0 1 2.756-.679L14 7h2.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-3.232a1 1 0 0 1-.5-.134l-1.291-.745-1.285 2.14 3.803 1.522a1 1 0 0 1 .577 1.244L12.582 20a.5.5 0 0 1-.633.316l-1.898-.632a.5.5 0 0 1-.316-.633l1.395-4.183-3.142-1.257a2 2 0 0 1-.972-2.886l2.47-4.118Z" })] }) })));
};
