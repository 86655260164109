var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Walk = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M13.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-.659 7.118a.5.5 0 0 1-.162-.466l.646-3.553c.272-1.495-1.158-2.736-2.6-2.255l-2.051.684a2 2 0 0 0-.968.697L5.1 10.7a.5.5 0 0 0 .1.7l1.608 1.206a.5.5 0 0 0 .694-.092l2.57-3.29-.497 2.729a2 2 0 0 0 .65 1.863l3.48 3.045L15.42 22a.5.5 0 0 0 .632.316l1.898-.632a.5.5 0 0 0 .316-.633l-1.885-5.654a1 1 0 0 0-.29-.437l-3.249-2.842Z" }), _jsx("path", { d: "M6.106 21.553a.5.5 0 0 1-.224-.671l2.553-5.106a.5.5 0 0 1 .67-.223l1.79.894a.5.5 0 0 1 .223.671l-2.553 5.106a.5.5 0 0 1-.67.223l-1.79-.894ZM14.4 9.8a.5.5 0 0 0 .1.7l3.2 2.4a.5.5 0 0 0 .7-.1l1.2-1.6a.5.5 0 0 0-.1-.7l-3.2-2.4a.5.5 0 0 0-.7.1l-1.2 1.6Z" })] }) })));
};
