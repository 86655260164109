import { init as initSentry } from '@sentry/browser';
import ReactGA from 'react-ga';

export const isDevelopment = () => process.env.NODE_ENV === 'development';

const gaTrackingId = 'UA-159422043-1';

export const initTrackers = () => {
  console.info(`Application v. ${process.env.REACT_APP_VERSION}`);
  if (!isDevelopment()) {
    ReactGA.initialize(gaTrackingId, {
      gaOptions: {
        anonymizeIp: true,
        allowLinker: false,
      },
    });
    recordPage({ pathname: window.location.pathname });
    initSentry({
      dsn: 'https://a708e3589fd14b3ba09456bc15de0d9d@sentry.io/1835096',
      maxBreadcrumbs: 50,
      release: process.env.REACT_APP_VERSION,
      environment: process.env.APP_ENV,
    });
  }
};

export const recordPage = (location) => {
  if (!isDevelopment() && location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
};
