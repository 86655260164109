import React from 'react';
import cx from 'classnames';
import { Exclamation } from '@ruter-ds-next/icons';
import Track from '../Track';
import LineNumber from '../LineNumber';

import './styles.scss';

interface Props {
  departures: Mon.Departure[];
  useColumnLayout: boolean;
  hideTrackColumn: boolean;
  situation?: Mon.SituationVisual;
}

const Departures: React.FC<Props> = ({ departures, useColumnLayout, hideTrackColumn, situation }: Props) => {
  const departureLines = situation ? departures.slice(0, departures.length - 1) : departures;

  return (
    <div className="departures">
      {departureLines.map((d, i) => {
        const hasSituation = d.situations?.filter((situation) => situation.reportType === 'incident')?.length > 0;
        const activeSituation =
          hasSituation && d.situations.some((sit) => sit.situationNumber === situation?.situationNumber);
        return (
          <div className={cx('departure', { rows: !useColumnLayout, hideTrackColumn })} key={i}>
            <LineNumber lineNumber={d.lineNumber} transportMode={d.transportMode} />
            <div className={cx('frontText', { '--cancellation': d.cancellation })}>
              <span>{d.frontText}</span>
            </div>
            <div className="eta">{d.cancellation ? 'Innstilt' : d.etaText}</div>
            {!hideTrackColumn && <Track quayPublicCode={d.quayPublicCode} transportMode={d.transportMode} />}
            <div className={cx('situations', { '-active-situation': activeSituation, '-has-situation': hasSituation })}>
              <Exclamation />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Departures;
