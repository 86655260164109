var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const TicketOutline = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M8.2 7.4h1.1a.3.3 0 0 1 .3.3v1.1a.3.3 0 0 1-.3.3H8.2a.3.3 0 0 1-.3-.3V7.7a.3.3 0 0 1 .3-.3Zm1.1 2.5H8.2a.3.3 0 0 0-.3.3v3.6a.3.3 0 0 0 .3.3h1.1a.3.3 0 0 0 .3-.3v-3.6a.3.3 0 0 0-.3-.3Zm-1.1 5h1.1a.3.3 0 0 1 .3.3v1.1a.3.3 0 0 1-.3.3H8.2a.3.3 0 0 1-.3-.3v-1.1a.3.3 0 0 1 .3-.3Z" }), _jsx("path", { d: "M22 6.5a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v2c0 .552.455.983.992 1.111C4.162 9.89 5 10.691 5 12c0 1.31-.839 2.11-2.008 2.389-.537.128-.992.559-.992 1.111v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2c0-.552-.454-.983-.992-1.111C19.838 14.111 19 13.309 19 12c0-1.31.839-2.11 2.008-2.389.538-.128.992-.559.992-1.111v-2ZM4 6h16a.5.5 0 0 1 .5.5v1.693c-.758.207-1.491.597-2.054 1.227-.618.693-.946 1.583-.946 2.58 0 .997.328 1.887.946 2.58.563.63 1.296 1.02 2.054 1.227V17.5a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5v-1.693c.758-.207 1.491-.597 2.054-1.227.618-.693.946-1.583.946-2.58 0-.997-.328-1.887-.946-2.58C4.99 8.79 4.258 8.4 3.5 8.193V6.5A.5.5 0 0 1 4 6Z", fillRule: "evenodd" })] }) })));
};
