import axios from 'axios';
import { Entur } from '@ruter-as/dpi-frontend';
import { formatDepartures } from '../utils/departures';
import { buildDepartureQuery } from './builder';
import { getLinesSuggestionsQuery, getStopPlacesByCoordsQuery } from './queries';
import { getStopPlaceNamesQuery } from './queries';
import { groupLineSuggestionsByStopPlaces, mapNearestEdgesToStopPlaces } from './mapUtils';

const JourneyPlannerEndpoint = 'https://api.entur.io/journey-planner/v3/graphql';
const clientIdentifier = 'ruter-mon';

export const getDepartures = (boardsConfig: Mon.BoardConfig[] = []) => {
  const query = buildDepartureQuery(boardsConfig);

  if (query) {
    return axios
      .post<{ data: Entur.Result }>(
        JourneyPlannerEndpoint,
        {
          query: query,
        },
        {
          headers: {
            'ET-Client-Name': clientIdentifier,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => res.data)
      .then(({ data }) => {
        const departures = formatDepartures(data, boardsConfig);
        return Promise.resolve(departures);
      });
  }
  return Promise.reject('Empty query');
};

export const getLinesSuggestions = (stopPlaceIds) => {
  const query = getLinesSuggestionsQuery(stopPlaceIds);
  return axios
    .post<{ data: { stopPlaces: any } }>(
      JourneyPlannerEndpoint,
      { query: query },
      {
        headers: {
          'ET-Client-Name': clientIdentifier,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res.data)
    .then(({ data }) => {
      const stopPlaces = groupLineSuggestionsByStopPlaces(data);
      return Promise.resolve(stopPlaces);
    });
};

export const getStopPlacesByCoords = async (latitude, longitude) => {
  const query = getStopPlacesByCoordsQuery({ latitude, longitude });
  return axios
    .post<{ data: { nearest: any } }>(
      JourneyPlannerEndpoint,
      { query: query },
      {
        headers: {
          'ET-Client-Name': clientIdentifier,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res.data)
    .then(({ data }) => {
      const stopPlaces = mapNearestEdgesToStopPlaces(data);
      const stopPlace = stopPlaces.length ? stopPlaces.slice(0, 2) : [];
      return Promise.resolve(stopPlace);
    });
};

export const getStopPlaceNames = (stopPlaceIds) => {
  const query = getStopPlaceNamesQuery(stopPlaceIds);
  return axios
    .post<{ data: { stopPlaces: any } }>(
      JourneyPlannerEndpoint,
      { query },
      {
        headers: {
          'ET-Client-Name': clientIdentifier,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res.data);
};
