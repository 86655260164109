var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const CityBike = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M15.505 6.675a.25.25 0 0 0-.238-.175H14.25a.25.25 0 0 1-.25-.25v-1a.25.25 0 0 1 .25-.25h2.116c.11 0 .206.07.239.175l.418 1.325h4.148a1 1 0 0 1 .963 1.269l-.667 2.388a1.25 1.25 0 0 1-1.204.914h-1.796l.264.836a3.929 3.929 0 1 1-1.443.412l-.34-1.077a3.25 3.25 0 0 0-1.606 1.19l-1.834 2.57a3.84 3.84 0 0 1-.096.128 1.571 1.571 0 0 1-2.795 1.438 3.737 3.737 0 0 1-.16.003H8.785A3.93 3.93 0 0 1 1 15.821a3.929 3.929 0 0 1 5.596-3.558l1.217-1.37L6.718 9.25H5.25A.25.25 0 0 1 5 9V8a.25.25 0 0 1 .25-.25h4.5A.25.25 0 0 1 10 8v1a.25.25 0 0 1-.25.25H8.52l3.338 5.006a1.59 1.59 0 0 1 .327.005c.036-.043.07-.086.102-.132l1.835-2.568a4.75 4.75 0 0 1 2.374-1.75l-.99-3.136Zm2.488 2.896L17.497 8h3.015l-.439 1.571h-2.08Zm.216 5.667-.459-1.455a2.429 2.429 0 1 0 1.45-.387l.44 1.39a.25.25 0 0 1-.163.313l-.954.302a.25.25 0 0 1-.314-.163Zm-7.613-.17-1.923-2.884-.861.969c.487.526.832 1.186.974 1.918h1.67c.047 0 .093-.001.14-.004ZM4.05 16.5H2.596a2.43 2.43 0 1 0 4.212-2.217l-1.45 1.63a1.75 1.75 0 0 1-1.307.587Z", fillRule: "evenodd" }) }) })));
};
