var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Truck = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M18 7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h.5c.276 0 .496-.226.541-.498a3 3 0 0 1 5.918 0c.045.273.265.498.541.498h4c.276 0 .496-.226.541-.498a3 3 0 0 1 5.918 0c.045.273.265.498.541.498H22a1 1 0 0 0 1-1v-4.697a1 1 0 0 0-.168-.555l-1.535-2.303A1 1 0 0 0 20.465 9H18V7Zm0 4h1.732a.5.5 0 0 1 .416.223l1.334 2a.5.5 0 0 1-.416.777H18v-3Z", fillRule: "evenodd" }), _jsx("path", { d: "M8 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm11 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" })] }) })));
};
