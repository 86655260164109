var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const TicketDay = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M20 4.5a2 2 0 0 1 2 2v2c0 .552-.454.983-.992 1.111C19.838 9.89 19 10.691 19 12c0 1.31.839 2.11 2.008 2.389.538.128.992.559.992 1.111v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2c0-.552.455-.983.992-1.111C4.162 14.111 5 13.309 5 12c0-1.31-.839-2.11-2.008-2.389C2.455 9.483 2 9.052 2 8.5v-2a2 2 0 0 1 2-2h16ZM7.65 15h3.802v-.88H9.154v-.04l1.17-1.284c.625-.677 1.102-1.248 1.102-2.02 0-.97-.77-1.674-2.09-1.674-.535 0-1.049.12-1.43.324v.972c.307-.273.79-.438 1.302-.438.704 0 1.159.321 1.159.892 0 .492-.344.892-.727 1.307l-1.99 2.165V15Zm8.76-1.986h-.722v-1.57h-.994v1.57H13.32v-.045l1.742-3.784-.986-.003-1.81 3.858v.84h2.415V15h1.008v-1.12h.722v-.866Z", fillRule: "evenodd" }) }) })));
};
