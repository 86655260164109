import React from 'react';
import { ArrowLeft } from '@ruter-ds-next/icons';
import { Link } from 'react-router-dom';

const BackButton = () => {
  return (
    <div className="flex md:ml-5 ml-2">
      <ArrowLeft className="w-6 h-6" />
      <Link className="ml-2" to={`/departures/${window.location.pathname.split('/').slice(2).join('/')}`}>
        Tilbake til visningen
      </Link>
    </div>
  );
};

export default BackButton;
