import React from 'react';
import cx from 'classnames';
import { getDisplayText } from '../../utils/track';

import './styles.scss';

interface Props {
  quayPublicCode: string;
  transportMode: Mon.TransportMode;
  small?: boolean;
  className?: string;
}

const Track = ({ quayPublicCode = '', transportMode, small = false, className = '' }: Props) => {
  const displayText = getDisplayText(transportMode, quayPublicCode);

  return (
    <div
      className={cx('track', className, {
        '-empty': !quayPublicCode,
        '-small': small,
      })}
    >
      <div className={cx('track-label', { '-small': small })}>{displayText}</div>
    </div>
  );
};

export default Track;
