var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Refresh = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M19.5 12a.53.53 0 0 0-.518.5A7 7 0 0 1 6.255 16H8.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H4a1 1 0 0 0-1 1v4.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1.843A9 9 0 0 0 20.986 12.5a.48.48 0 0 0-.486-.5h-1ZM12 3a8.983 8.983 0 0 1 7 3.343V4.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V9a1 1 0 0 1-1 1h-4.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h2.245a7 7 0 0 0-12.728 3.5.53.53 0 0 1-.517.5h-1a.48.48 0 0 1-.486-.5A9 9 0 0 1 12 3Z" }) }) })));
};
