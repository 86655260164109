import React, { useEffect, useState, useCallback } from 'react';
import Departures from '../Departures';
import DeparturesHeader from '../DeparturesHeader';
import BoardHeader from '../BoardHeader';
import { getLinesSuggestions, getStopPlaceNames } from '../../data/graphql';
import { getStopPlacesTransportModes } from '../../utils/lines';

import './styles.scss';

interface Props {
  departures?: Mon.Departure[];
  config: any;
  useColumnLayout: boolean;
  situation?: Mon.SituationVisual;
}

const Board: React.FC<Props> = ({ departures = [], config, useColumnLayout, situation }: Props) => {
  const [transportModes, setTransportModes] = useState<Mon.TransportMode[]>([]);
  const [title, setTitle] = useState(config ? config.title : '');

  useEffect(() => {
    const stopPlaceIds = config.stopPlaceIds;
    const disabledTransportModes = config.disabledTransportModes;
    if (stopPlaceIds.length) {
      getLinesSuggestions(stopPlaceIds).then((stopPlacesWithLines) => {
        const transportModes = getStopPlacesTransportModes(stopPlacesWithLines, disabledTransportModes);
        setTransportModes(transportModes);
      });
    }
  }, [config]);

  useEffect(() => {
    if (config && !config.title) {
      // Use a default if no title is set
      getStopPlaceNames(config.stopPlaceIds).then(({ data }) => {
        if (data.stopPlaces && data.stopPlaces.length) {
          setTitle(data.stopPlaces[0].name);
        }
      });
    }
  }, [config]);

  return (
    <div className="board">
      <BoardHeader title={title} transportModes={transportModes} useColumnLayout={useColumnLayout} />
      <DeparturesHeader useColumnLayout={useColumnLayout} hideTrackColumn={config.hideTrackColumn} />
      <Departures
        departures={departures}
        useColumnLayout={useColumnLayout}
        hideTrackColumn={config.hideTrackColumn}
        situation={situation}
      />
    </div>
  );
};

export default Board;
