var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const HomeAlt = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M19 8a2 2 0 1 0-1.112-3.663 2 2 0 0 0-3.711-.16A2 2 0 0 0 15 8h4Z" }), _jsx("path", { d: "M3 14.828a2 2 0 0 1 .586-1.414l3.707-3.707a1 1 0 0 1 1.414 0L10 11v-.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V12h8.22a1 1 0 0 1 .97.758l.655 2.62a.5.5 0 0 1-.485.622H21v4h.75a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25H2.25a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25H3v-5.172ZM6 20h2v-2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V20Zm8 0h5v-2.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V20Zm-3.5-6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1Z", fillRule: "evenodd" })] }) })));
};
