import React from 'react';
import { getIconWithoutCircle } from '../../shared/components/Icons';
import { Entur } from '@ruter-as/dpi-frontend';

interface Props {
  transportModes: Entur.TransportMode[] | null;
}
const TransportIconsPanel = ({ transportModes }: Props) => {
  const icons = (transportModes || []).map((tm) =>
  getIconWithoutCircle(tm, '', {
      fill: '#000',
    })
  );
  return <div className="transportIconsPanel flex">{icons}</div>;
};

export default TransportIconsPanel;
