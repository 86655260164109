var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const PersonAlt = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M12 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-4.416 8.528a2 2 0 0 1-1.348-2.18l.273-1.914A4 4 0 0 1 10.47 9h3.062a4 4 0 0 1 3.96 3.434l.273 1.914a2 2 0 0 1-1.348 2.18L15 17v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4l-1.416-.472Z" }) }) })));
};
