const supportedTransportModes = ['rail', 'bus', 'metro', 'tram', 'water', 'coach'];

export const transportModeLabels: Record<Mon.TransportMode, string> = {
  rail: 'Tog',
  bus: 'Buss',
  metro: 'T-bane',
  tram: 'Trikk',
  water: 'Båt',
  coach: 'Andre kjøretøy',
};

export const getWhiteListedModes = (excludedModes: string[] = []) =>
  supportedTransportModes.filter((supported) => !excludedModes.includes(supported));
