var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Micromobility = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M11.467 6a.5.5 0 0 0-.485.621l.25 1a.5.5 0 0 0 .485.379h3.265a.25.25 0 0 1 .177.427l-2.78 2.78a.5.5 0 0 0-.132.475l.395 1.577a.24.24 0 0 0 .402.111l3.633-3.633.252.84a4 4 0 1 0 1.916-.574l-1.539-5.129a.25.25 0 0 1 .16-.309l1.376-.458a.5.5 0 0 0 .316-.633l-.316-.948a.5.5 0 0 0-.633-.317l-2.525.842a1 1 0 0 0-.642 1.236L15.556 6h-4.089Zm6.202 7.043c-.055-.184-.286-.235-.385-.07a2 2 0 1 0 2.583-.776c-.173-.083-.338.086-.283.27l.23.767a.5.5 0 0 1-.335.622l-.958.288a.5.5 0 0 1-.623-.336l-.23-.765ZM4.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2.72l1.76 7.043a1 1 0 0 1-.19.868l-2.97 3.714a1 1 0 0 1-.781.375H1.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H6a1 1 0 0 0 .78-.375l2.93-3.662.16.641a2.5 2.5 0 1 0 2.255-.525.571.571 0 0 1-.424-.4L8.97 4.758A1 1 0 0 0 8 4H4.5Zm8 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z", fillRule: "evenodd" }) }) })));
};
