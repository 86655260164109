var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Compass = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M14.596 9.101a.25.25 0 0 1 .303.303l-.792 3.168a.25.25 0 0 1-.42.116l-2.375-2.376a.25.25 0 0 1 .116-.42l3.168-.79Z" }), _jsx("path", { d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM8.617 16.596a1 1 0 0 1-1.213-1.213l1.305-5.219a2 2 0 0 1 1.455-1.455l5.219-1.305a1 1 0 0 1 1.213 1.213l-1.305 5.219a2 2 0 0 1-1.455 1.455l-5.22 1.305Z", fillRule: "evenodd" })] }) })));
};
