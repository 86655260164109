var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const ScooterPerson = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M12 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" }), _jsx("path", { d: "M16 8.5a.5.5 0 0 1 .5-.5H18a1 1 0 0 1 .976.783l1.754 7.89a.572.572 0 0 0 .423.413 2.501 2.501 0 1 1-2.268.506l-.081-.366-2.758 3.102A2 2 0 0 1 14.55 21h-8.8a.563.563 0 0 0-.41.192A2.5 2.5 0 1 1 5.95 19h.549l-.947-1.895a.5.5 0 0 1 .223-.67l1.106-.553 1.053-2.106a.5.5 0 0 1 .67-.223l1.652.825-1.464-1.464a1 1 0 0 1-.293-.707V7.934c0-1.597 1.78-2.55 3.11-1.664l1.844 1.23H14.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1.651a1 1 0 0 1-.555-.168l-.794-.53v1.577l2.414 2.414a2 2 0 0 1 .586 1.414V19h.05l3.383-3.76a1 1 0 0 0 .233-.885L17.198 10H16.5a.5.5 0 0 1-.5-.5v-1ZM11.5 19v-3.38l-.51-.51a.25.25 0 0 0-.4.065l-1.174 2.346a2 2 0 0 1-.894.894L7.354 19H11.5Zm-8 1.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm17 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z", fillRule: "evenodd" })] }) })));
};
