interface GetEstimatedCallsFragment {
  showSituation: boolean;
}

export const getEstimatedCallsFragment = ({ showSituation = false } : GetEstimatedCallsFragment) => `fragment estimatedCallsParts on EstimatedCall {
      realtime
      cancellation
      serviceJourney {
        id
      }
      destinationDisplay {
        frontText
      }
      ${getSituationsQuery(showSituation)}
      quay {
        publicCode
        id
        stopPlace {
          parent {
            id
          }
          id
        }
      }
      expectedDepartureTime
      actualDepartureTime
      aimedDepartureTime
      serviceJourney {
        id
        line {
          id
          publicCode
          transportMode
        }        
      }
}`;

export const getSituationsQuery = (showSituation) => {
  if (showSituation) {
    return `
  situations {
    severity
    situationNumber
    reportType
    summary {
      value
      language
    }
    description {
      value
      language
    }
    validityPeriod {
      startTime
      endTime
    }
  }`;
  }
  return '';
};