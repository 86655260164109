var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Ticket = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M20 4.5a2 2 0 0 1 2 2v2c0 .552-.454.983-.992 1.111C19.838 9.89 19 10.691 19 12c0 1.31.839 2.11 2.008 2.389.538.128.992.559.992 1.111v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2c0-.552.455-.983.992-1.111C4.162 14.111 5 13.309 5 12c0-1.31-.839-2.11-2.008-2.389C2.455 9.483 2 9.052 2 8.5v-2a2 2 0 0 1 2-2h16ZM8.25 6.9a.35.35 0 0 0-.35.35v1c0 .193.157.35.35.35h1a.35.35 0 0 0 .35-.35v-1a.35.35 0 0 0-.35-.35h-1Zm0 3a.35.35 0 0 0-.35.35v3.5c0 .193.157.35.35.35h1a.35.35 0 0 0 .35-.35v-3.5a.35.35 0 0 0-.35-.35h-1Zm-.35 5.85a.35.35 0 0 1 .35-.35h1a.35.35 0 0 1 .35.35v1a.35.35 0 0 1-.35.35h-1a.35.35 0 0 1-.35-.35v-1Z", fillRule: "evenodd" }) }) })));
};
