var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const PositionOutline = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "m6.94 10.911 3.197 1.066c.89.297 1.59.996 1.886 1.886l1.066 3.198 4.1-10.25-10.25 4.1Zm-3.268 1.02 5.832 1.943a.982.982 0 0 1 .622.622l1.944 5.832c.29.869 1.504.904 1.844.054L19.927 5.35c.321-.802-.475-1.598-1.277-1.277L3.618 10.086c-.85.34-.814 1.555.054 1.844Z", fillRule: "evenodd" }) }) })));
};
