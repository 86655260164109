var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const DisabledPerson = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M13 3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" }), _jsx("path", { d: "M11.667 11.25a1.1 1.1 0 0 1-.416-.646l-.677-3.384a2 2 0 0 0-2.446-1.548l-3.758.94a1 1 0 0 0-.706.653l-.929 2.786a.5.5 0 0 0 .316.633l1.41.47A6 6 0 1 0 14 16h2.074l2.362 4.724a.5.5 0 0 0 .67.223l1.79-.894a.5.5 0 0 0 .223-.671l-2.915-5.83A1 1 0 0 0 17.31 13h-4.112a6.03 6.03 0 0 0-1.53-1.75ZM8.07 10l-.232-1.163-1.675.418-.39 1.172A5.984 5.984 0 0 1 8.07 10ZM8 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z", fillRule: "evenodd" })] }) })));
};
