var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const TicketYear = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M11.077 13.133c0 .611.398 1.097.963 1.097.557 0 .955-.469.955-1.11-.003-.637-.403-1.083-.946-1.083-.56 0-.972.48-.972 1.096Z" }), _jsx("path", { d: "M19.5 2a.5.5 0 0 1 .5.5V4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V4h6.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5h1ZM9.175 13.4c0 .952-.701 1.68-2.119 1.68-.679 0-1.196-.17-1.528-.358v-.96c.35.235.815.434 1.395.434.75 0 1.167-.332 1.167-.852 0-.54-.446-.912-1.23-.912h-.543v-.841h.526c.69 0 1.108-.327 1.108-.858.003-.457-.31-.761-1.017-.761-.492 0-.892.145-1.213.375v-.932c.383-.196.84-.313 1.36-.313 1.24 0 1.89.637 1.89 1.523 0 .653-.36 1.176-.997 1.34v.046c.75.1 1.201.634 1.201 1.39Zm4.888-.36c0 1.182-.858 2.045-2.023 2.04-1.062-.003-2.159-.74-2.096-2.898.051-1.898.954-3.088 2.596-3.088.444 0 .839.09 1.137.216l-.003.951a2.062 2.062 0 0 0-1.077-.292c-1.318 0-1.58 1.15-1.608 1.946h.04c.261-.458.733-.736 1.321-.736.992 0 1.713.781 1.713 1.86Zm4.432.02c0 1.21-.818 2.011-2.219 2.011-.437 0-.997-.077-1.446-.332v-.966c.5.306.91.426 1.361.426.88 0 1.219-.48 1.216-1.051-.003-.563-.321-.955-.864-.958-.29 0-.573.108-.855.4l-.804-.3.281-3.108h3.023v.88h-2.15l-.117 1.447h.034c.208-.14.526-.236.887-.236.991 0 1.656.676 1.653 1.787Z", fillRule: "evenodd" })] }) })));
};
