import React from 'react';

export enum LogoColor {
  white = 'white',
  deepblue = 'deepblue',
  red = 'red',
}

const colors = {
  [LogoColor.deepblue]: '#313663',
  [LogoColor.red]: '#E60000',
  [LogoColor.white]: '#E5E8FF',
};

interface Props {
  className?: string;
  color?: LogoColor;
  title?: string;
  description?: string;
}

export default function Logo({
  className = '',
  color = LogoColor.white,
  title = 'Ruter logo',
  description = "A vector based illustration with the name 'Ruter' combined with the network symbol",
}: Props) {
  const fill = colors[color];
  return (
    <svg
      width="1550"
      height="750"
      viewBox="0 0 1550 750"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <desc>{description}</desc>
      <path
        d="M375.166 488.154H420.661L371.979 399.281C398.463 388.664 412.419 366.117 412.419 335.69C412.419 292.895 384.726 264 333.078 264H250V488.154H290.77V406.067H330.99L375.166 488.154ZM290.77 297.93H326.814C357.254 297.93 370.88 311.83 370.88 335.69C370.88 359.55 357.254 372.356 327.034 372.356H290.77V297.93Z"
        fill={fill}
      />
      <path
        d="M557.719 417.449C557.719 443.061 539.368 455.757 521.785 455.757C502.664 455.757 489.917 442.295 489.917 420.952V320.039H450.136V427.081C450.136 467.468 473.213 490.343 506.4 490.343C531.675 490.343 549.478 477.1 557.17 458.274H558.928V488.154H597.5V320.039H557.719V417.449Z"
        fill={fill}
      />
      <path
        d="M727.566 350.028V320.039H689.764V276.258L649.874 287.422V320.039H623.72L623.5 350.028H649.874V439.449C649.874 472.941 666.357 490.562 699.654 490.562C709.435 490.562 719.544 488.92 727.457 485.09V453.896C722.402 456.852 716.797 458.384 710.424 458.384C696.028 458.384 689.764 450.613 689.764 432.554V350.028H727.566Z"
        fill={fill}
      />
      <path
        d="M896.572 402.783C896.572 343.899 866.901 317.85 825.363 317.85C775.472 317.85 747.23 357.799 747.23 405.191C747.23 457.508 776.241 491 836.461 491C853.055 491 870.088 487.279 884.264 481.368V448.424C871.297 455.319 856.022 459.697 839.319 459.697C801.626 459.697 787.56 441.419 786.351 414.932H896.572V402.783ZM786.351 388.226C787.34 366.774 802.066 348.715 825.692 348.715C848.659 348.715 860.747 365.351 860.857 388.226H786.351Z"
        fill={fill}
      />
      <path
        d="M928.735 488.154H968.516V389.321C968.516 367.978 984.67 352.874 1006.54 352.874C1013.35 352.874 1021.59 354.078 1025 355.172V318.725C1021.37 318.068 1015.22 317.631 1010.71 317.631C991.373 317.631 975.219 328.576 969.065 348.058H967.307V320.039H928.735V488.154Z"
        fill={fill}
      />
      <path
        d="M1240 352.724L1231.97 399.273H1287.2L1281.12 432.054H1226.22L1216.45 488H1181.73L1191.49 432.054H1140.06L1130.29 488H1095.57L1105.34 432.054H1050L1056.08 399.273H1111.09L1119.12 352.724H1064.76L1070.62 319.943H1124.87L1134.63 264H1169.36L1159.59 319.946H1211.02L1220.79 264H1255.51L1245.75 319.946H1300L1294.36 352.727H1240V352.724ZM1205.27 352.724H1153.84L1145.81 399.273H1197.24L1205.27 352.724Z"
        fill={fill}
      />
    </svg>
  );
}
