var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Thunder = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M7 16a5 5 0 0 1-1.252-9.842c.2-.052.358-.21.41-.41a5.002 5.002 0 0 1 9.646-.138.593.593 0 0 0 .631.422A5 5 0 1 1 17 16h-3.25L13 17h1.793a.5.5 0 0 1 .353.854l-3.983 3.983a.555.555 0 0 1-.92-.568L11 19H8.809a.5.5 0 0 1-.447-.724L9.5 16H7Z" }) }) })));
};
