var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const CellPhoneDownload = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M13 10a.25.25 0 0 1-.25-.25v-2.5A.25.25 0 0 0 12.5 7h-1a.25.25 0 0 0-.25.25v2.5A.25.25 0 0 1 11 10h-.966a.25.25 0 0 0-.192.41l1.966 2.36c.1.12.284.12.384 0l1.966-2.36a.25.25 0 0 0-.192-.41H13Zm.75 4a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-3.5a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h3.5Z" }), _jsx("path", { d: "M6 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V4Zm2 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V5Zm3.25 14a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-1.5Z", fillRule: "evenodd" })] }) })));
};
