var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Senior = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M13.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-5.74 6.361.252.63.788-.59a.5.5 0 0 1 .7.1l2.6 3.466a2 2 0 0 1 .4 1.2V21.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-4l-.926 4.312a.5.5 0 0 1-.587.386l-1.968-.394a.5.5 0 0 1-.392-.588l.815-4.073-1.712-4.28a2 2 0 0 1 .443-2.157l2.939-2.939 1.822-.911a2 2 0 0 1 2.559.68l1.47 2.204 2.037.68a.5.5 0 0 1 .316.632l-.632 1.897a.5.5 0 0 1-.633.316l-2.688-.896a1 1 0 0 1-.516-.394l-1.36-2.04-.598.298-2.129 2.128ZM19 12.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-9Z" }) }) })));
};
