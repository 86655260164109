var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Cart = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M21.987 8.22c.134-1.184-.805-2.22-2.01-2.22H7.002l-.35-3.11A1.008 1.008 0 0 0 5.648 2H2.506A.503.503 0 0 0 2 2.5v1c0 .276.227.5.506.5H4.81l1.136 10.11c.057.507.49.89 1.006.89h.612l-2.15 1.7a.995.995 0 0 0-.38.78v1.02c0 .276.227.5.506.5h1.012c-.838 0-1.517.672-1.517 1.5s.68 1.5 1.517 1.5c.839 0 1.518-.672 1.518-1.5S7.391 19 6.553 19h12.143c-.839 0-1.518.672-1.518 1.5s.68 1.5 1.518 1.5 1.517-.672 1.517-1.5-.68-1.5-1.517-1.5h1.011c.28 0 .506-.224.506-.5v-1c0-.276-.226-.5-.506-.5H8.071l1.975-1.562A2.04 2.04 0 0 1 11.311 15h8.103a2.015 2.015 0 0 0 2.011-1.78l.562-5Z" }) }) })));
};
