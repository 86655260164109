var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Tram = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M12.411 5.416 10.827 7h-7.96a1 1 0 0 0-.99.858l-.806 5.647a1 1 0 0 0 .283.848l1.353 1.354a1 1 0 0 0 .707.293H4.75a.25.25 0 0 1 .25.25v.25a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v.25a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-.25a.25.25 0 0 1 .25-.25h1.336a1 1 0 0 0 .707-.293l1.353-1.354a1 1 0 0 0 .283-.848l-.806-5.647a1 1 0 0 0-.99-.858h-8.891l1.23-1.231a.5.5 0 0 0 0-.707l-1.89-1.89a.25.25 0 0 0-.354 0l-.354.353a.25.25 0 0 0 0 .354l1.537 1.537ZM15 9H9v3h6V9Zm1 0h3.5a.5.5 0 0 1 .5.5V12h-4V9ZM8 9H4.5a.5.5 0 0 0-.5.5V12h4V9Z", fillRule: "evenodd" }), _jsx("path", { d: "M1 17.25a.25.25 0 0 1 .25-.25h21.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25H1.25a.25.25 0 0 1-.25-.25v-.5Z" })] }) })));
};
