var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Envelope = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M22 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v.177a.5.5 0 0 0 .232.421l8.694 5.533a2 2 0 0 0 2.148 0l8.694-5.533A.5.5 0 0 0 22 7.177V7Z" }), _jsx("path", { d: "M22 10.732a.5.5 0 0 0-.768-.421l-8.158 5.191a2 2 0 0 1-2.148 0l-8.158-5.191a.5.5 0 0 0-.768.421V17a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6.268Z" })] }) })));
};
