import { getEstimatedCallsFragment } from './fragments';
import { stopPlacesDepartureQuery } from './queries';

export const buildDepartureQuery = (items: Mon.BoardConfig[] = []) => {
  if (!items.length) {
    return '';
  }
  const estimatedCallParts = getEstimatedCallsFragment({ showSituation: true });
  const startString = `${estimatedCallParts} query {`;
  const endString = ` }`;
  let graphqlString = '';

  items
    .filter((item) => item.numberOfDepartures > 0)
    .forEach((item, index) => {
      const { stopPlaceIds = [], disabledStopPlaces = [], disabledTransportModes = [], delayedStartTime = 0 } = item;
      const id = `board${index + 1}`;

      if (Array.isArray(stopPlaceIds) && stopPlaceIds.length) {
        const filteredStopPlaceIds = stopPlaceIds.filter((stopPlaceId) => !disabledStopPlaces.includes(stopPlaceId));
        graphqlString += stopPlacesDepartureQuery(
          id,
          filteredStopPlaceIds,
          200,
          disabledTransportModes,
          delayedStartTime
        );
      } else {
        console.warn('No stopPlace or quayIds specified, omitting query');
      }
    });

  return startString + graphqlString + endString;
};
