import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Boards from '../../shared/components/Boards';
import Header from '../../shared/components/Header';
import { DEFAULT_SETTINGS, loadURLState } from '../../shared/utils/URL';
import { setupBoards } from '../../shared/utils/boards';
import Snow from '../Snow';
import useTitle from '../../utils/use-title';

import './styles.scss';

const Departures = () => {
  useTitle('- Avganger');
  const { coords } = useParams();
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);

  useEffect(() => {
    const settingsFromURL = loadURLState();
    const { boards } = settingsFromURL;

    if ((!boards || !boards.length) && coords) {
      const [latitude, longitude] = coords.split('-');
      setupBoards(latitude, longitude, settingsFromURL, (newSettings) => {
        setSettings(newSettings);
      });
    } else {
      setSettings(settingsFromURL);
    }
  }, [coords]);

  /**
   * The Festive season is officially starting in December and lasts until
   * 15th of January we decided here in Ruter DPI 🎄
   */
  const currentDate = new Date();
  const itsTheFestiveSeason =
    currentDate.getMonth() === 11 || (currentDate.getMonth() === 0 && currentDate.getDate() <= 15);

  return (
    <div className="departures-route">
      <Header navigateToHome={true} navigateToSettings={true} showFullScreenToggle={true} />
      <Boards boardsConfig={settings.boards} useColumnLayout={settings.useColumnLayout} />
      {itsTheFestiveSeason && !settings.hideAnimations && <Snow />}
    </div>
  );
};

export default Departures;
