import { getStopPlaceNames, getStopPlacesByCoords } from '../data/graphql';
import { getDefaultBoardSettings } from './URL';

export const setupBoards = (
  latitude: string,
  longitude: string,
  settingsFromURL: Mon.Settings,
  callback: Mon.AnyFunction
) => {
  getStopPlacesByCoords(latitude, longitude)
    .then((stopPlaces) => {
      const stopPlacesBoard1 = stopPlaces.length > 1 ? stopPlaces.slice(0, 1) : stopPlaces;
      const stopPlacesBoard2 = stopPlaces.length > 1 ? stopPlaces.slice(1) : [];

      const newBoards = [
        {
          ...getDefaultBoardSettings(stopPlacesBoard1.map((s) => s.name).join(', ')),
          stopPlaceIds: stopPlacesBoard1.map((s) => s.id),
          numberOfDepartures: 8,
        },
        {
          ...getDefaultBoardSettings(stopPlacesBoard2.map((s) => s.name).join(', ')),
          stopPlaceIds: stopPlacesBoard2.map((s) => s.id),
          numberOfDepartures: 8,
        },
      ];
      callback({ ...settingsFromURL, boards: newBoards });
    })
    .catch((error) => {
      console.error('Unable to setup boards', error);
      callback([]);
    });
};

export const getAvailableStopPlaces = (settings: Mon.Settings) => {
  const availableStopPlaces =
    settings && settings.boards
      ? settings.boards.map((board) => board.stopPlaceIds).reduce((tail, item) => [...tail, ...item], [])
      : [];
  return getStopPlaceNames(availableStopPlaces);
};
