import React, { useState, useEffect, useMemo } from 'react';
import ReactAutosuggest from 'react-autosuggest';
import { Position, ExclamationCircle } from '@ruter-ds-next/icons';
import { Input } from '@ruter-ds-next/input';
import { useDebounce } from '../../shared/utils/debounce';
import { getFeatures } from '../../services/api';
import { mapFeaturesToSuggestions } from '../../services/mapUtils';
import CategoriesPanel from '../CategoriesPanel';

import './styles.scss';

const MY_POSITION = 'MY_POSITION';
const DEBOUNCED_VALUE = 500;
const POSITION_ERROR_CODES = Object.freeze({
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 2,
});

const fetchSuggestions = async (value) => {
  if (!value.trim().length) return [];

  let searchText = value;

  // And this is where I planted the easter egg - Sean
  if ((value || '').toLowerCase() === 'hardcore interaksjonsdesigner') {
    searchText = 'Huldrefaret';
  }

  const featuresData = await getFeatures(searchText, undefined, {
    layers: 'address,venue',
  });
  return mapFeaturesToSuggestions(featuresData);
};

interface Props {
  label?: String;
  handleSelectItem: (coordinates: Mon.Coordinates | null) => void;
}

export default function StopPlaceSearch({ handleSelectItem, label }: Props) {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [positionError, setPositionError] = useState<string | null>(null);
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, DEBOUNCED_VALUE);

  const onChange = (_, { newValue }) => {
    setValue(newValue);
  };

  const getSuggestionValue = (suggestion) => {
    if (suggestion.name === MY_POSITION) {
      return 'Min posisjon';
    }
    return suggestion.name;
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion.name === MY_POSITION) {
      return (
        <div>
          <span>Min posisjon</span>
          <Position className="w-6 h-6 geolockIcon" />
        </div>
      );
    }
    const suggestionString =
      suggestion.locality === suggestion.county
        ? `${suggestion.locality}`
        : `${suggestion.locality}, ${suggestion.county}`;
    return (
      <div className="suggestion">
        <span className="suggestionName">
          {suggestion.name}
          <CategoriesPanel categories={suggestion.category} />
        </span>
        <span className="suggestionDescription">{suggestionString}</span>
      </div>
    );
  };

  useEffect(() => {
    fetchSuggestions(debouncedValue).then((suggestions) => setSuggestions([{ name: MY_POSITION }, ...suggestions]));
  }, [debouncedValue]);

  const onSuggestionsFetchRequested = ({ value: newValue }) => {
    setValue(newValue);
  };

  const onPositionSuccess = ({ coords }) => {
    setLoadingPosition(false);
    handleSelectItem(coords);
  };

  const onPositionError = (error) => {
    setLoadingPosition(false);
    const humanErrorCode = ((code) => {
      switch (code) {
        case POSITION_ERROR_CODES.PERMISSION_DENIED:
          return 'Du må gi tillatelse til å bruke din posisjon';
        case POSITION_ERROR_CODES.POSITION_UNAVAILABLE:
          return 'Din posisjon er utilgjengelig. Prøv igjen.';
        case POSITION_ERROR_CODES.TIMEOUT:
          return 'Det tok for lang tid å hente din posisjon';
        default:
          return 'En ukjent feil har oppstått';
      }
    })(error.code);

    setPositionError(humanErrorCode);
    handleSelectItem(null);
  };

  const onSuggestionSelected = (_, { suggestion }) => {
    if (suggestion.name === MY_POSITION) {
      setLoadingPosition(true);
      navigator.geolocation.getCurrentPosition(onPositionSuccess, onPositionError);
    } else {
      handleSelectItem(suggestion.coordinates);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([{ name: MY_POSITION }]);
  };

  const renderInputComponent = (inputProps) => <Input {...inputProps} label={label} />;

  const inputProps = useMemo(
    () => ({
      placeholder: 'Adresse eller sted',
      value,
      onChange,
      onFocus: () => {
        setPositionError(null);
      },
    }),
    [value]
  );

  return (
    <div className="stop-place-search">
      <ReactAutosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        shouldRenderSuggestions={() => true}
        inputProps={inputProps}
        focusInputOnSuggestionClick={false}
      />
      {positionError && (
        <span className="positionError">
          <ExclamationCircle className="w-4 h-4" />
          <span className="positionErrorLabel">{positionError}</span>
        </span>
      )}
    </div>
  );
}
