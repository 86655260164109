var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const BusAltOutline = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M5.6 4.75a.85.85 0 0 0-.85.85v6.084c0 .618.328 1.217.91 1.52A13.694 13.694 0 0 0 12 14.75a13.69 13.69 0 0 0 6.34-1.546c.582-.303.91-.902.91-1.52V5.6a.85.85 0 0 0-.85-.85H5.6Zm.65 1.75a.25.25 0 0 1 .25-.25h11a.25.25 0 0 1 .25.25v5.184a.21.21 0 0 1-.102.19A12.202 12.202 0 0 1 12 13.25c-2.039 0-3.959-.497-5.648-1.377-.052-.027-.102-.093-.102-.189V6.5Z", fillRule: "evenodd" }), _jsx("path", { d: "M6 14.6a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8Zm12 0a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8Z" }), _jsx("path", { d: "M19 2H5a3 3 0 0 0-3 3v12a3 3 0 0 0 2.5 2.959V21a1 1 0 0 0 1 1H7a1 1 0 0 0 1-1v-1h8v1a1 1 0 0 0 1 1h1.5a1 1 0 0 0 1-1v-1.041A3 3 0 0 0 22 17V5a3 3 0 0 0-3-3ZM5 3.5h14A1.5 1.5 0 0 1 20.5 5v12a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 17V5A1.5 1.5 0 0 1 5 3.5Z", fillRule: "evenodd" })] }) })));
};
