var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Trophy = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M5 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3h1.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-1c-.276 0-.493.229-.572.493-.125.418-.406.78-.796 1.003l-3.628 2.073a1 1 0 0 0-.504.868V17.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1.063a1 1 0 0 0-.504-.868l-3.628-2.073a1.723 1.723 0 0 1-.796-1.003C4.993 12.229 4.776 12 4.5 12h-1a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5H5V3Zm11 17.5a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-1Z" }) }) })));
};
