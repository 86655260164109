var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const VolumeMute = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M10.586 19.586c1.26 1.26 3.414.367 3.414-1.414V5.828c0-1.781-2.154-2.674-3.414-1.414L7.293 7.707A1 1 0 0 1 6.586 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h2.586a1 1 0 0 1 .707.293l3.293 3.293Zm5.12-9.466a.5.5 0 0 1 0-.707l.707-.707a.5.5 0 0 1 .707 0l2.122 2.122 2.122-2.12a.5.5 0 0 1 .707 0l.707.706a.5.5 0 0 1 0 .707l-2.121 2.121 2.122 2.123a.5.5 0 0 1 0 .707l-.707.707a.5.5 0 0 1-.707 0l-2.122-2.122-2.121 2.12a.5.5 0 0 1-.707 0l-.707-.707a.5.5 0 0 1 0-.707l2.12-2.12-2.122-2.123Z" }) }) })));
};
