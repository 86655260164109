var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Pen = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M22.107 7.343a.5.5 0 0 0 0-.707l-4.243-4.242a.5.5 0 0 0-.707 0l-.707.707a.5.5 0 0 0 0 .707l4.242 4.242a.5.5 0 0 0 .707 0l.707-.707Zm-6.718-1.768a1 1 0 0 0-1.414 0l-8.81 8.81a2 2 0 0 0-.52.906L3.45 19.828a1 1 0 0 0 1.222 1.221l4.536-1.194a2 2 0 0 0 .905-.52l8.81-8.81a1 1 0 0 0 0-1.414L15.39 5.575Z" }) }) })));
};
