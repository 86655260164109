var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Plane = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M10.393 10.801a.49.49 0 0 0-.094-.764L4.495 6.535A.49.49 0 0 1 4.4 5.77l.173-.174a1.936 1.936 0 0 1 1.811-.522l2.751.638a1 1 0 0 0 .933-.267l.87-.87a.5.5 0 0 1 .707 0l.707.707a.5.5 0 0 1 0 .708l-.38.38 1.77.412a.968.968 0 0 0 .907-.261l2.639-2.654a1.94 1.94 0 0 1 .993-.535l1.555-.313a.974.974 0 0 1 1.157 1.067l-.122 1.1a1.959 1.959 0 0 1-.557 1.166l-2.917 2.933a.981.981 0 0 0-.26.91l.434 1.89.438-.439a.5.5 0 0 1 .707 0l.708.708a.5.5 0 0 1 0 .707l-.934.933a1 1 0 0 0-.267.93l.613 2.672a1.962 1.962 0 0 1-.52 1.822l-.172.173a.484.484 0 0 1-.76-.094l-3.483-5.837a.484.484 0 0 0-.76-.094l-3.399 3.417a.98.98 0 0 0-.285.691v1.955a.98.98 0 0 1-.284.69l-.534.538a.485.485 0 0 1-.805-.191L6.787 17.5a.487.487 0 0 0-.308-.309l-3.147-1.055a.49.49 0 0 1-.19-.809l.534-.537a.97.97 0 0 1 .688-.286h1.943a.97.97 0 0 0 .687-.286l3.399-3.418Z" }) }) })));
};
