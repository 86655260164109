export const mapNearestEdgesToStopPlaces = ({ nearest }) => {
  try {
    return nearest.edges.filter((edge) => !!edge && !!edge.node).map((edge) => edge.node.place);
  } catch (error) {
    // TODO: use Logger to gracefully log error
    return [];
  }
};

export const groupLineSuggestionsByStopPlaces = ({ stopPlaces }) => {
  try {
    return stopPlaces.map(({ name, id, quays, transportMode }) => ({
      name,
      id,
      transportMode,
      lines: quays
        .filter((quay) => quay.estimatedCalls.length)
        .map((quay) => ({
          ec: quay.estimatedCalls.map(
            ({
              destinationDisplay: { frontText },
              serviceJourney: {
                line: { publicCode, transportMode },
              },
            }) => ({
              publicCode,
              transportMode,
              frontText,
              quay: { id: quay.id, publicCode: quay.publicCode },
            })
          ),
        }))
        .reduce((tail, current) => [...tail, ...current.ec], [])
        .sort((a, b) =>
          a.publicCode.localeCompare(b.publicCode, 'nb', {
            sensitivity: 'base',
          })
        ),
    }));
  } catch (error) {
    // TODO: use Logger to gracefully log error
    return [];
  }
};

function maptoVisualSituation(situation: Mon.Situation): Mon.SituationVisual[] {
  return situation.summary.map((summary) => {
    return {
      ...summary,
      id: `${situation.situationNumber}-${summary.language}`,
      severity: situation.severity,
      situationNumber: situation.situationNumber,
      reportType: situation.reportType,
      validityPeriod: situation.validityPeriod,
    };
  });
}

export const mapSituations = (departures: Mon.Departure[][]): Mon.SituationVisual[] => {
  const uniqueSituationNumbers = new Set();

  return departures
    .flat()
    .filter((dep) => dep.situations && dep.situations.length)
    .map((dep) => dep.situations)
    .flat()
    .filter((current) => {
      // Filter duplicates
      const { situationNumber } = current;
      if (!uniqueSituationNumbers.has(situationNumber)) {
        uniqueSituationNumbers.add(situationNumber);
        return true;
      }
      return false;
    })
    .filter((current) => {
      // Filter only reportType incident
      return current.reportType === 'incident';
    })
    .reduce((sum, item) => {
      return [...sum, ...maptoVisualSituation(item)];
    }, [] as Mon.SituationVisual[]);
};
