var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const ScanQrcode = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M16 3.5a.5.5 0 0 0 .5.5H19a1 1 0 0 1 1 1v2.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V4a2 2 0 0 0-2-2h-3.5a.5.5 0 0 0-.5.5v1Zm-12 13a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V20a2 2 0 0 0 2 2h3.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H5a1 1 0 0 1-1-1v-2.5Zm18 0a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V19a1 1 0 0 1-1 1h-2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H20a2 2 0 0 0 2-2v-3.5ZM7.5 4a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H4a2 2 0 0 0-2 2v3.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V5a1 1 0 0 1 1-1h2.5ZM7 7.5a.5.5 0 0 1 .5-.5H11v2h2v2h2v4h-2v-2h-2v-2H7V7.5Z" }), _jsx("path", { d: "M11 15H9v2H7.5a.5.5 0 0 1-.5-.5V13h4v2Zm0 0v2h2v-2h-2Zm4 0h2v1.5a.5.5 0 0 1-.5.5H15v-2Zm0-4V9h-2V7h3.5a.5.5 0 0 1 .5.5V11h-2Z" })] }) })));
};
