var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const SunCloud = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M14.526 4.423a.5.5 0 0 1 .948 0l.307.919a.5.5 0 0 1-.475.658h-.612a.5.5 0 0 1-.475-.658l.307-.919ZM10.54 7.974a.5.5 0 0 1-.8-.13l-.433-.866a.5.5 0 0 1 .67-.671l.867.433a.5.5 0 0 1 .13.8l-.433.434ZM21 12.306a.5.5 0 0 0 .658.475l.919-.307a.5.5 0 0 0 0-.948l-.919-.307a.5.5 0 0 0-.658.475v.612Zm-.74-4.462a.5.5 0 0 1-.8.13l-.434-.433a.5.5 0 0 1 .13-.8l.866-.434a.5.5 0 0 1 .671.67l-.433.867Zm-2.342 7.16c.068.406.543.628.817.32a5 5 0 0 0-7.197-6.932c-.19.183-.174.482-.002.68.189.219.362.45.518.694.093.145.25.24.422.253a6.003 6.003 0 0 1 5.442 4.985Z" }), _jsx("path", { d: "M8 9a4.002 4.002 0 0 0-3.817 2.799.59.59 0 0 1-.384.385A4.002 4.002 0 0 0 5 20h8a4 4 0 1 0-.57-7.96.623.623 0 0 1-.667-.4A4.002 4.002 0 0 0 8 9Z" })] }) })));
};
