import React, { useEffect } from 'react';
import { ErrorBoundary as InternalErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';
import { isDevelopment } from '../../config/trackers';

const defaultOnErrorHandler = (error) => {
  if (!isDevelopment()) {
    Sentry.captureException(error);
  }
};

const ErrorContent = () => {
  useEffect(() => {
    document.title = 'Ruter MON - Feil har oppstått';

    return () => {
      document.title = 'Ruter MON';
    };
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <h1>En uventet feil har oppstått :(</h1>
      <p>Vi beklager! Feilen som har oppstått er blitt rapportert.</p>
      <a href={window.location.protocol + '//' + window.location.host}>Gå tilbake til hovedsiden</a>
    </div>
  );
};

const ErrorBoundary = (props) => (
  <InternalErrorBoundary onError={defaultOnErrorHandler} FallbackComponent={ErrorContent} {...props} />
);
export default ErrorBoundary;
