import lz from 'lz-string';

const SCHEMA_VERSION = 2;

export const DEFAULT_SETTINGS: Mon.Settings = Object.freeze({
  useColumnLayout: true,
  boards: [],
  version: SCHEMA_VERSION,
});

export function getDefaultBoardSettings(title: string): Mon.BoardConfig {
  return {
    stopPlaceIds: [],
    delayedStartTime: 0,
    hiddenLines: {},
    numberOfDepartures: 8,
    title: title || 'Tavle uten tittel',
    disabledTransportModes: [],
    enabled: true,
    hideTrackColumn: false,
  };
}

const migrateSettings = (settings: Mon.Settings): Mon.Settings => {
  return {
    ...DEFAULT_SETTINGS,
    ...settings,
    version: SCHEMA_VERSION,
    boards: settings.boards.map((board) => ({
      ...getDefaultBoardSettings(board.title),
      ...board,
    })),
  };
};

export const loadURLState = () => {
  const hash = getHash();
  if (!hash) {
    return DEFAULT_SETTINGS;
  }
  const decompressed = lz.decompressFromEncodedURIComponent(hash);
  const settings = JSON.parse(decompressed) as Mon.Settings;

  if (SCHEMA_VERSION > settings.version) {
    return migrateSettings(settings);
  }

  return settings;
};

export const updateURLState = (settings: Mon.Settings) => {
  const newPathname = window.location.pathname.split('/').slice(0, 3).join('/') + '/' + createURLStateHash(settings);
  window.history.pushState(window.history.state, document.title, newPathname);

  return newPathname;
};

const getHash = () => {
  return window.location.pathname.split('/')[3] || '';
};

export const createURLStateHash = (settings: Mon.Settings) => {
  const mergedSettings = {
    ...DEFAULT_SETTINGS,
    ...settings,
  };
  const hash = lz.compressToEncodedURIComponent(JSON.stringify(mergedSettings));
  return hash;
};

export const getPathName = (route: string) => {
  const pathParams = window.location.pathname.split('/').slice(2).join('/');

  return `/${route}/${pathParams}`;
};
