import React, { createRef, useEffect, useState } from 'react';

const MAX_PARTICLES = 20;

export type SnowParticle = {
  x: number;
  y: number;
  r: number;
  speed: number;
};

function SnowScreen() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const canvasRef = createRef<HTMLCanvasElement>();

  function snow(ctx, particles) {
    ctx.clearRect(0, 0, windowWidth, windowHeight);

    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
    ctx.beginPath();

    particles.forEach((part, i) => {
      ctx.moveTo(part.x, part.y);
      ctx.arc(part.x, part.y, part.r, 0, Math.PI * 2, true);
    });
    ctx.fill();
  }

  useEffect(() => {
    var ctx = canvasRef.current?.getContext('2d');
    let raf = -1;
    let angle = 0;

    if (!ctx) {
      return;
    }

    let particles = Array(MAX_PARTICLES)
      .fill(0)
      .map((_, i) => {
        return {
          x: Math.random() * windowWidth,
          y: Math.random() * windowHeight,
          r: Math.random() * 4 + 1,
          speed: Math.random() * 3 + 1,
        };
      });

    const render = () => {
      angle += 0.005;
      particles = particles.map((part) => {
        if (part.y > windowHeight) {
          return {
            ...part,
            y: -(part.r * 2),
            x: part.x + Math.sin(angle),
          };
        }
        return {
          ...part,
          y: part.y + part.speed,
          x: part.x + Math.sin(angle),
        };
      });

      snow(ctx, particles);

      raf = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      window.cancelAnimationFrame(raf);
    };
  }, [snow]);

  const canvasStyle = {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    pointerEvents: 'none',
  };

  return <canvas ref={canvasRef} width={windowWidth} height={windowHeight} style={canvasStyle}></canvas>;
}

export default SnowScreen;
