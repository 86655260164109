var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Layers = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M3.994 13.506a.5.5 0 0 0-.574 0l-.835.584a.5.5 0 0 0 0 .82l9.128 6.39a.5.5 0 0 0 .574 0l9.128-6.39a.5.5 0 0 0 0-.82l-.835-.584a.5.5 0 0 0-.574 0L12 19.11l-8.006-5.604Z" }), _jsx("path", { d: "M12.287 2.7a.5.5 0 0 0-.574 0L2.585 9.09a.5.5 0 0 0 0 .82l9.128 6.39a.5.5 0 0 0 .574 0l9.128-6.39a.5.5 0 0 0 0-.82L12.287 2.7Zm6.226 6.8L12 14.059 5.488 9.5 12 4.941 18.513 9.5Z", fillRule: "evenodd" })] }) })));
};
