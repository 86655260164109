import React from 'react';
import Header from '../../shared/components/Header';
import useTitle from '../../utils/use-title';
import { LogoColor } from '../../shared/components/Icons/Logo';

import './styles.scss';

const Privacy = () => {
  useTitle('- Personvern');
  
  return (
    <div className="privacy">
      <Header logoColor={LogoColor.red} navigateToSettings={false} />
      <div className="content">
        <h2 className="text-headline-l-reg">Personvern</h2>
        <div className="description">
          <p>
            Ruter MON bruker informasjonskapsler (cookies), dersom du har godtatt dette i nettleseren din.
            Informasjonskapsler er små tekstfiler som lagres av nettleseren på datamaskinen din.
          </p>
          <h3 className="text-headline-m-md">Analyseverktøy</h3>
          <p>
            Ruter bruker analyseverktøyet <a href="https://analytics.google.com/">Google Analytics</a> for å samle
            statistikk om bruken av nettstedet, slik at vi får innsikt i hvordan vi kan gjøre tjenesten enda bedre. Vi
            får informasjon om hvilke sider som blir brukt, hvor brukerne kommer fra, tid brukt på sidene, og nettleser
            og enhetskategori (mobil, desktop, m.m.).
          </p>
          <p>
            Google Analytics sletter eventuelle personidentifiserende opplysninger ved mottak, og IP-adressen din blir
            anonymisert.
          </p>
        </div>
        <div className="description">
          <h3 className="text-headline-m-md">Verktøy for feilhåndtering</h3>
          <p>
            Ruter bruker feilhåndteringsverktøyet <a href="https://sentry.io">Sentry</a> for å rapportere uventet
            kjøretidsfeil. Meldingen er ment til å hjelpe utviklerne med å kunne feilsøke og rette feilen for å
            forhindre at feilen skjer igjen.
          </p>
          <p>Sentry mottar ingen personidentifiserende opplysninger, og IP-adressen din blir anonymisert.</p>
        </div>
        <div className="below">
          <p>
            Dersom du har noen spørsmål rundt dette, kontakt oss gjerne på{' '}
            <a href="mailto:dpi@ruter.no" className="link">
              dpi@ruter.no
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
