import React, { useState } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { CogWheel } from '@ruter-ds-next/icons';
import Clock from '../../../components/Clock';
import { getPathName } from '../../utils/URL';
import FullScreenToggleButton from '../FullScreenToggleButton';
import Logo, { LogoColor } from '../Icons/Logo';

import './styles.scss';

interface CtaButtonsProps {
  isIdle: boolean;
}
const CtaButtons = ({ isIdle }: CtaButtonsProps) => {
  const navigate = useNavigate();
  const navigateToSettings = (e) => {
    const url = getPathName('settings');
    navigate(url);
    e.preventDefault();
  };

  return (
    <div className={cx('ctaButtons', { '-hidden': isIdle })}>
      <div
        className="settings"
        onClick={(e) => {
          navigateToSettings(e);
        }}
      >
        Innstillinger
        <CogWheel className="w-6 h-6 cogwheel fill-main-white" />
      </div>
    </div>
  );
};

interface HeaderProps {
  logoColor?: LogoColor;
  showClock?: boolean;
  navigateToSettings?: boolean;
  showFullScreenToggle?: boolean;
  navigateToHome?: boolean;
}

const Header = ({
  logoColor = LogoColor.white,
  showClock = true,
  navigateToSettings = true,
  showFullScreenToggle = false,
  navigateToHome = false,
}: HeaderProps) => {
  const [isIdle, setIsIdle] = useState(false);
  const navigate = useNavigate();

  useIdleTimer({
    onIdle: () => {
      setIsIdle(true);
    },
    onActive: () => {
      setIsIdle(false);
    },
    timeout: 5000,
    throttle: 500,
  });

  return (
    <div className="header">
      <div
        className={cx('logoWrapper', { '-clickable': navigateToHome })}
        onClick={() => {
          navigateToHome && navigate('/');
        }}
      >
        <Logo
          color={logoColor}
          title="Ruter primary logo"
          description="A vector based illustration with the name 'Ruter' combined with the network symbol"
          className="ruterLogo"
        />
      </div>
      {navigateToSettings && <CtaButtons isIdle={isIdle} />}
      {showFullScreenToggle && <FullScreenToggleButton isIdle={isIdle} />}
      {showClock && <Clock />}
    </div>
  );
};

export default Header;
