import React, { useEffect, useMemo, useRef, useState } from 'react';

export function useSituations() {
  const [situations, setSituations] = useState<Mon.SituationVisual[]>([]);
  const [situationIndex, setSituationIndex] = useState<number>(0);
  const timer = useRef<number>();
  const intervalRef = useRef<number>();

  function loop() {
    if (timer.current !== undefined && Date.now() < timer.current) {
      return;
    }

    let nextSituation = 0;

    setSituationIndex((currVal) => {
      const nextIndex = currVal < situations.length - 1 ? currVal + 1 : 0;

      nextSituation = nextIndex;

      timer.current = Date.now() + 10000;

      return nextIndex;
    });
  }

  useEffect(() => {
    intervalRef.current = setInterval(loop, 500);

    return () => {
      clearInterval(intervalRef.current);
    };
  });

  const currentSituation = useMemo(() => {
    return situations[situationIndex];
  }, [situationIndex, situations]);

  const currentSituationSize = useMemo(() => {
    if (!currentSituation) {
      return 0;
    }
    if (currentSituation.value.length < 80) {
      return 1;
    }
    if (currentSituation.value.length < 150) {
      return 2;
    }
    return 3;
  }, [currentSituation]);

  return {
    currentSituation,
    currentSituationSize,
    situationIndex,
    situations,
    setSituations,
  };
}
