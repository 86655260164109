import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router';
import LandingPage from './components/LandingPage';
import Settings from './components/Settings';
import Departures from './components/Departures';
import Privacy from './components/Privacy';
import { recordPage } from './config/trackers';

import './app.scss';

export const ROUTE_PATHS = Object.freeze({
  LANDING_PAGE: '/',
  DEPARTURES: 'departures/:coords/*',
  SETTINGS: 'settings/:coords/*',
  PRIVACY: 'privacy',
});

function AppRoutes() {
  /* Must be within Router */
  const location = useLocation();

  useEffect(() => {
    recordPage(location);
  }, [location]);

  return (
    <Routes>
      <Route path={ROUTE_PATHS.LANDING_PAGE} element={<LandingPage />} />
      <Route path={ROUTE_PATHS.PRIVACY} element={<Privacy />} />
      <Route path={ROUTE_PATHS.DEPARTURES} element={<Departures />} />
      <Route path={ROUTE_PATHS.SETTINGS} element={<Settings />} />
    </Routes>
  );
}

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
};

export default App;
