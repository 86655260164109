var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const TicketMonth = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M13.096 11.743c-.003 1.56.46 2.388 1.33 2.388.863 0 1.33-.828 1.33-2.388-.007-1.56-.477-2.397-1.33-2.397-.863 0-1.33.838-1.33 2.397Z" }), _jsx("path", { d: "M19.5 2a.5.5 0 0 1 .5.5V4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V4h6.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5h1Zm-8.412 11.2c0 1.071-.79 1.89-2.387 1.89-.758 0-1.34-.192-1.713-.403v-1.08c.383.262.908.489 1.566.489.847 0 1.317-.374 1.317-.96 0-.606-.505-1.025-1.384-1.025h-.61v-.946h.587c.78 0 1.247-.368 1.25-.965.003-.515-.348-.857-1.144-.857a2.29 2.29 0 0 0-1.368.422V8.717a3.35 3.35 0 0 1 1.53-.352c1.394 0 2.126.716 2.126 1.713 0 .735-.406 1.323-1.122 1.509v.05c.857.116 1.352.723 1.352 1.564Zm5.796-1.463c0 2.192-.88 3.39-2.458 3.388-1.582 0-2.464-1.193-2.461-3.382 0-2.183.888-3.378 2.46-3.378 1.57 0 2.459 1.195 2.459 3.372Z", fillRule: "evenodd" })] }) })));
};
