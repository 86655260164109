export const mapFeaturesToSuggestions = ({ data: { features } = [] }) => {
  return features
    .map(({ geometry, properties: { id, name, locality, county, category } }) => {
      return {
        coordinates: {
          longitude: geometry.coordinates[0],
          latitude: geometry.coordinates[1],
        },
        id,
        name,
        locality,
        county,
        category,
      };
    })
    .filter((suggestion) => suggestion.county && suggestion.locality)
    .filter((suggestion) => !(suggestion.category || []).includes('GroupOfStopPlaces'))
    .slice(0, 5);
};
