var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const CrossCircle = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M4.929 19.071c3.905 3.905 10.237 3.905 14.142 0 3.905-3.905 3.905-10.237 0-14.142-3.905-3.905-10.237-3.905-14.142 0-3.905 3.905-3.905 10.237 0 14.142Zm6.894-8.662a.25.25 0 0 0 .354 0l2.298-2.298a.5.5 0 0 1 .707 0l.707.707a.5.5 0 0 1 0 .707l-2.298 2.298a.25.25 0 0 0 0 .354l2.298 2.298a.5.5 0 0 1 0 .707l-.707.707a.5.5 0 0 1-.707 0l-2.298-2.298a.25.25 0 0 0-.354 0l-2.298 2.298a.5.5 0 0 1-.707 0l-.707-.707a.5.5 0 0 1 0-.707l2.298-2.298a.25.25 0 0 0 0-.354L8.111 9.525a.5.5 0 0 1 0-.707l.707-.707a.5.5 0 0 1 .707 0l2.298 2.298Z", fillRule: "evenodd" }) }) })));
};
