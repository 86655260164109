import { Entur } from '@ruter-as/dpi-frontend';

export const getStopPlaceTransportModes = (stopPlace: Entur.LocationStopPlace) => {
  if (!stopPlace || !stopPlace.lines) {
    return [];
  }
  const transportModes = new Set<Entur.TransportMode>();
  stopPlace.lines.forEach((line) => {
    transportModes.add(line.transportMode);
  });
  return Array.from(transportModes);
};

export const sortTransportModes = (transportModes: Entur.TransportMode[] = []) => {
  const preferredOrder = ['metro', 'tram', 'bus', 'water', 'rail'];
  return transportModes.sort((a, b) => {
    if (preferredOrder.indexOf(a) === -1) {
      return 1;
    }
    if (preferredOrder.indexOf(b) === -1) {
      return -1;
    }
    return preferredOrder.indexOf(a) - preferredOrder.indexOf(b);
  });
};

export const getStopPlacesTransportModes = (
  stopPlaces: Entur.LocationStopPlace[],
  disabledTransportModes: Entur.TransportMode[] = []
) => {
  if (!Array.isArray(stopPlaces) || !stopPlaces.length) {
    return [];
  }

  const transportModes = (stopPlaces as Entur.LocationStopPlace[])
    .map((stopPlace) =>
      getStopPlaceTransportModes(stopPlace).filter((transportMode) => !disabledTransportModes.includes(transportMode))
    )
    .reduce((tail, current) => [...tail, ...current], []);

  const uniqueTransportModes = new Set(transportModes);
  const sortedTransportModes = sortTransportModes(Array.from(uniqueTransportModes));
  return sortedTransportModes;
};

export const createHiddenLineString = (quayId: string, linePublicCode: string, lineFrontText: string) =>
  `${quayId}-${linePublicCode}-${lineFrontText}`;

export const isLineHidden = (
  hiddenLines: any = {},
  stopPlaceId: string,
  quayId: string,
  linePublicCode: string,
  lineFrontText: string
) => {
  const hiddenLineString = createHiddenLineString(quayId, linePublicCode, lineFrontText);
  return (hiddenLines[stopPlaceId] || []).includes(hiddenLineString);
};
