var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Profile = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M18 20a9.985 9.985 0 0 0 4-8c0-5.523-4.477-10-10-10S2 6.477 2 12a9.98 9.98 0 0 0 4 8 9.956 9.956 0 0 0 6 2 9.956 9.956 0 0 0 6-2Zm-.033-1.947A8.472 8.472 0 0 1 12 20.5a8.473 8.473 0 0 1-5.967-2.447A3 3 0 0 1 9 15.5h6a3 3 0 0 1 2.967 2.553ZM8.5 10.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z", fillRule: "evenodd" }) }) })));
};
