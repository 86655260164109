var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Support = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M19 11v.5a.5.5 0 0 1-.5.5H18a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3.167a.333.333 0 0 1-.333-.333.667.667 0 0 0-.667-.667H11a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h3.333a.667.667 0 0 0 .667-.667c0-.184.15-.333.333-.333H19a2 2 0 0 0 2-2v-8a9 9 0 1 0-18 0v5a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-.5a.5.5 0 0 1-.5-.5V11a7 7 0 0 1 14 0Z" }), _jsx("path", { d: "M13 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-.5 2a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h1Z" })] }) })));
};
