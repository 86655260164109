import axios from 'axios';

const clientName = 'ruter-mon';

const authInstance = axios.create({});

const getPositionParamsFromGeolocationResult = (coords) => {
  if (!coords) {
    return {};
  }

  const { latitude, longitude } = coords;
  return {
    'focus.point.lat': latitude,
    'focus.point.lon': longitude,
  };
};

export const getFeatures = (text, coords, params = {}) => {
  const searchParams = {
    ...getPositionParamsFromGeolocationResult(coords),
    lang: 'no',
    text,
    ...params,
  };

  const host = 'https://api.entur.io/geocoder/v1';
  const headers = {
    'ET-Client-Name': clientName,
  };
  const url = `${host}/autocomplete`;
  return axios.get(url, {
    params: searchParams,
    headers,
  });
};

export default authInstance;
