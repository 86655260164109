var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Sun = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M13.066 4a.5.5 0 0 0 .416-.777l-1.066-1.599a.5.5 0 0 0-.832 0l-1.066 1.599a.5.5 0 0 0 .416.777h2.132Zm4.089.746a.5.5 0 0 0-.255.844l1.51 1.51a.5.5 0 0 0 .844-.256l.378-1.888a.5.5 0 0 0-.588-.589l-1.889.38ZM12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm10.376-6.416a.5.5 0 0 1 0 .832l-1.599 1.066a.5.5 0 0 1-.777-.416v-2.132a.5.5 0 0 1 .777-.416l1.599 1.066Zm-3.122 5.572a.5.5 0 0 0-.844-.256l-1.51 1.51a.5.5 0 0 0 .255.844l1.889.379a.5.5 0 0 0 .588-.589l-.378-1.888Zm-6.838 5.22a.5.5 0 0 1-.832 0l-1.066-1.599a.5.5 0 0 1 .416-.777h2.132a.5.5 0 0 1 .416.777l-1.066 1.599Zm-7.67-5.22a.5.5 0 0 1 .844-.256l1.51 1.51a.5.5 0 0 1-.255.844l-1.889.379a.5.5 0 0 1-.588-.589l.378-1.888ZM4 10.934a.5.5 0 0 0-.777-.416l-1.599 1.066a.5.5 0 0 0 0 .832l1.599 1.066A.5.5 0 0 0 4 13.066v-2.132Zm2.845-6.188a.5.5 0 0 1 .255.844L5.59 7.1a.5.5 0 0 1-.844-.256l-.378-1.888a.5.5 0 0 1 .588-.589l1.889.38Z" }) }) })));
};
