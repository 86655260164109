var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const PeopleThree = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M16.05 6.5c0 2.21-1.778 4-3.97 4s-3.97-1.79-3.97-4 1.778-4 3.97-4 3.97 1.79 3.97 4Zm-9.924 10c0-2.21 1.777-4 3.97-4h3.969c2.192 0 3.97 1.79 3.97 4v3c0 1.105-.89 2-1.986 2H8.11a1.992 1.992 0 0 1-1.984-2v-3Zm11.303-7.002c-.325-.012-.48-.372-.354-.674.298-.715.463-1.5.463-2.324 0-.824-.165-1.61-.463-2.324-.126-.302.029-.662.354-.674l.11-.002c1.643 0 2.976 1.343 2.976 3s-1.333 3-2.977 3c-.037 0-.073 0-.11-.002ZM20.515 19a1.992 1.992 0 0 0 1.985-2v-2c0-2.21-1.777-4-3.97-4h-1.032c-.466.005-.504.616-.12.883A5.005 5.005 0 0 1 19.523 16v2.5c0 .276.222.5.496.5h.496Zm-17.03 0A1.992 1.992 0 0 1 1.5 17v-2c0-2.21 1.777-4 3.97-4h1.032c.466.005.504.616.12.883A5.005 5.005 0 0 0 4.477 16v2.5c0 .276-.222.5-.496.5h-.496Zm3.246-9.502c.325-.012.48-.372.354-.674A6.021 6.021 0 0 1 6.622 6.5c0-.824.165-1.61.463-2.324.126-.302-.029-.662-.354-.674a3.016 3.016 0 0 0-.11-.002c-1.643 0-2.976 1.343-2.976 3s1.333 3 2.977 3c.037 0 .073 0 .11-.002Z" }) }) })));
};
