import moment from 'moment';

export const getSecondsToDeparture = (isoString?: string): number | null => {
  if (!isoString) {
    return null;
  }
  try {
    const date = moment(isoString);
    const now = moment();
    return date.diff(now, 'seconds');
  } catch (error) {
    return null;
  }
};

export const getMilisecondsOffset = () => {
  return (60 - new Date().getSeconds()) * 1000;
};

export const getClock = () => {
  const now = new Date();
  return [now.getHours(), now.getMinutes()].map((d) => {
    return (d < 10 ? '0' + d : d) + '';
  });
};
