import React, { ReactElement, useState, FunctionComponent } from 'react';
import cx from 'classnames';
import { ChevronDown, ChevronUp } from '@ruter-ds-next/icons';

type AllowedChildren = ReactElement<TAccordionContent> | ReactElement<TAccordionHeading>;

type Props = {
  children: AllowedChildren[];
  className?: string;
};

export const Accordion: React.FC<Props> = function ({ children = [], className }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const childrenWithProps = React.Children.map<any, any>(children, (child) => {
    return React.cloneElement(child, {
      onClick: (e) => {
        setIsOpen(!isOpen);
      },
      isOpen: isOpen,
    });
  });

  return <div className={className}>{childrenWithProps}</div>;
};

type HeadingProps = React.PropsWithChildren & {
  onClick?: () => void;
  isOpen?: boolean;
  className?: string;
};

export type TAccordionHeading = FunctionComponent<HeadingProps>;

export const AccordionHeading: TAccordionHeading = function ({
  children,
  onClick,
  className,
  isOpen = false,
}: HeadingProps) {
  return (
    <div onClick={onClick} className={cx('flex gap-4 items-center cursor-pointer', className)}>
      <span className="flex gap-4 flex-1 items-center">{children}</span>
      {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
    </div>
  );
};

type ContentProps = React.PropsWithChildren & {
  isOpen?: boolean;
  className?: string;
};

export type TAccordionContent = FunctionComponent<ContentProps>;

export const AccordionContent: TAccordionContent = function ({ children, className, isOpen = false }: ContentProps) {
  return isOpen ? <div className={className}>{children}</div> : '';
};
