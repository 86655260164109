import React from 'react';
import { Radio } from '@ruter-ds-next/radio';
import { Switch } from '@ruter-ds-next/switch';
import cx from 'classnames';

import './styles.scss';

const ChooseLayout = ({ useColumnLayout, handleChangeColumnLayout, handleHideAnimation, settings }) => {
  const onlyOneBoardActive = !(settings.boards || []).every(
    (board) => board.enabled && (board.stopPlaceIds || []).length > 0
  );
  const selectedValue = onlyOneBoardActive ? 'row' : useColumnLayout ? 'column' : 'row';

  return (
    <div className="card layout">
      <div className="card-header">Velg oppsett</div>
      <div className={cx('chooseLayout', { '-disabled': onlyOneBoardActive })} tabIndex={-1}>
        <div className="radioWrapper">
          <Radio
            name="card-layout"
            value="column"
            label=""
            onChange={() => handleChangeColumnLayout(true)}
            selectedValue={selectedValue}
            className="radio"
          />
          <div
            className={'columnOption ' + (useColumnLayout ? 'selected' : '')}
            onClick={() => {
              handleChangeColumnLayout(true);
            }}
            tabIndex={-1}
          >
            <div className="table">
              <div className="table-label">1</div>
            </div>
            <div className="table">
              <div className="table-label">2</div>
            </div>
          </div>
        </div>
        <div className="radioWrapper" tabIndex={1}>
          <Radio
            name="card-layout"
            value="row"
            label=""
            onChange={() => handleChangeColumnLayout(false)}
            selectedValue={selectedValue}
            className="radio"
          />
          <div
            className={'rowOption ' + (!useColumnLayout ? 'selected' : '')}
            onClick={() => {
              handleChangeColumnLayout(false);
            }}
            tabIndex={-1}
          >
            <div className="table">
              <div className="table-label">1</div>
            </div>
            <div className="table">
              <div className="table-label">2</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-header">Instillinger</div>
      <div>
        <div className="flex gap-2">
          <Switch
            label="Fjern animasjoner"
            enabled={settings.hideAnimations}
            onChange={(checked) => handleHideAnimation(checked)}
          />
          Fjern animasjoner
        </div>
      </div>
    </div>
  );
};

export default ChooseLayout;
