import React, { useState, useEffect, useRef } from 'react';
import { getClock, getMilisecondsOffset } from '../../shared/utils/dates';

import './styles.scss';

function Clock() {
  const [clock, setClock] = useState(getClock());
  const clockOffsetTimeout = useRef();
  const clockInterval = useRef();

  useEffect(function () {
    const offsetInMS = getMilisecondsOffset();
    clockOffsetTimeout.current = setTimeout(() => {
      setClock(getClock());
      clockInterval.current = setInterval(() => {
        setClock(getClock());
      }, 60 * 1000);
    }, offsetInMS);

    return function () {
      if (clockOffsetTimeout.current) {
        clearTimeout(clockOffsetTimeout.current);
      }
      if (clockInterval) {
        clearInterval(clockInterval.current);
      }
    };
  }, []);

  return (
    <div className="clock">
      <span>{clock[0]}</span>
      <span className="clock-colon"> : </span>
      <span>{clock[1]}</span>
    </div>
  );
}

export default Clock;
