var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const FaceHappy = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M15.694 14.694a.467.467 0 0 1 .031.642A4.988 4.988 0 0 1 12 17c-1.48 0-2.81-.643-3.725-1.664a.467.467 0 0 1 .032-.643l.682-.682c.23-.229.612-.18.835.054.547.576 1.32.935 2.176.935a2.99 2.99 0 0 0 2.176-.935c.223-.235.606-.283.835-.054l.683.683Zm-4.91-4.134a.465.465 0 0 0-.027-.588l-.356-.414c-.23-.268-.662-.216-.908.037-.397.41-.92.66-1.493.66-.573 0-1.096-.25-1.493-.66-.246-.253-.678-.305-.908-.037l-.356.414a.465.465 0 0 0-.027.588C5.902 11.444 6.896 12 8 12c1.105 0 2.098-.556 2.784-1.44Zm7.972-.588a.465.465 0 0 1 .028.588C18.098 11.444 17.104 12 16 12c-1.105 0-2.098-.556-2.784-1.44a.465.465 0 0 1 .027-.588l.356-.414c.23-.268.662-.216.908.037.397.41.92.66 1.493.66.573 0 1.096-.25 1.493-.66.246-.253.678-.305.908-.037l.355.414Z" }), _jsx("path", { d: "M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z", fillRule: "evenodd" })] }) })));
};
