var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const ArrowBackDown = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M4 10.5a6.5 6.5 0 0 0 6.5 6.5h8.086l-1.94-1.94a.5.5 0 0 1 0-.707l.708-.707a.5.5 0 0 1 .707 0l3.646 3.647a1 1 0 0 1 0 1.414l-3.646 3.646a.5.5 0 0 1-.707 0l-.707-.707a.5.5 0 0 1 0-.707L18.585 19H10.5a8.5 8.5 0 0 1 0-17h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9A6.5 6.5 0 0 0 4 10.5Z" }) }) })));
};
