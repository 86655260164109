import { useEffect } from 'react';

const useTitle = (title: string = '') => {
  useEffect(() => {
    document.title = `Ruter MON ${title}`;

    return () => {
      document.title = `Ruter MON`;
    };
  }, [title]);
};

export default useTitle;
