import { addMinutes } from 'date-fns';
import { getWhiteListedModes } from '../utils/transportModes';

export const stringifyArray = (items: string[]) => items.map((item) => `"${item}"`).join(', ');

export const getLinesSuggestionsQuery = (stopPlaceIds: string[]) => `query {
  stopPlaces(ids: [${stringifyArray(stopPlaceIds)}]) {
    id
    name
    description
    latitude
    longitude
    transportMode
    transportSubmode
    quays {
      id
      publicCode
      estimatedCalls(numberOfDeparturesPerLineAndDestinationDisplay: 1, timeRange: 604800, numberOfDepartures: 200, omitNonBoarding: true, whiteListedModes: [tram, bus, metro, water, rail, coach]) {
        destinationDisplay {
          frontText
        }
        serviceJourney {
          line {
            transportMode
            transportSubmode
            publicCode
          }
        }
      }
    }
  }
}`;

export const getStopPlaceNamesQuery = (stopPlaceIds: string[]) => `query {
  stopPlaces(ids: [${stringifyArray(stopPlaceIds)}]) {
    id
    name
  }
}`;

export const getStopPlacesByCoordsQuery = ({ latitude, longitude }: Mon.Coordinates) => `{
  nearest(latitude: ${latitude}, longitude: ${longitude}, maximumDistance: 500, maximumResults: 5, filterByInUse: true, multiModalMode: parent, filterByPlaceTypes: [stopPlace]) {
    edges {
      node {
        distance
        place {
          ...on StopPlace {
            name
          }
          id
          latitude
          longitude
        }
      }
    }
  }
}
`;

export const stopPlacesDepartureQuery = (
  id: string,
  stopPlaceIds: string[],
  numberOfDepartures: number,
  disabledTransportModes: string[],
  delayedStartTime: number
) => {
  const whiteListedModes = getWhiteListedModes(disabledTransportModes);
  const startTime = addMinutes(new Date(), delayedStartTime).toISOString();
  const startTimeString = delayedStartTime > 0 ? `startTime: "${startTime}" ` : '';
  return `${id}: stopPlaces(ids: [${stringifyArray(stopPlaceIds)}]) {
    name
    estimatedCalls(${startTimeString}whiteListedModes: [${whiteListedModes}], numberOfDepartures: ${numberOfDepartures}, arrivalDeparture: departures, includeCancelledTrips: true, timeRange: 14400) {
      ...estimatedCallsParts
    }
  }`;
};
