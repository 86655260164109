var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Star = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M11.606 2.789a1 1 0 0 1 1.788 0l2.33 4.658a1 1 0 0 0 .894.553h4.247a1 1 0 0 1 .768 1.64l-3.276 3.932a1 1 0 0 0-.18.957l1.922 5.767c.299.896-.699 1.669-1.492 1.155l-5.564-3.6a1 1 0 0 0-1.086 0l-5.564 3.6c-.793.514-1.79-.259-1.491-1.155l1.903-5.71a1 1 0 0 0-.242-1.023L2.707 9.707C2.077 9.077 2.523 8 3.414 8h4.968a1 1 0 0 0 .894-.553l2.33-4.658Z" }) }) })));
};
