var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const CellPhoneArrowRight = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M7 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-4.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 0 .5.5H16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v1.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V4Zm7 15.25a.25.25 0 0 0-.25-.25h-1.5a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-.5Z", fillRule: "evenodd" }), _jsx("path", { d: "M10 8.04a.5.5 0 0 1 .812-.39l3.7 2.96a.5.5 0 0 1 0 .78l-3.7 2.96a.5.5 0 0 1-.812-.39V12.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 0 .5-.5V8.04Z" })] }) })));
};
