var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const HourglassTopHalf = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M5.5 3h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5v3.2a2 2 0 0 1-1.188 1.828l-2.284 1.015a.5.5 0 0 0 0 .914l2.284 1.015A2 2 0 0 1 18 15.3v3.2a.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5v-3.2a2 2 0 0 1 1.188-1.828l2.284-1.015a.5.5 0 0 0 0-.914l-2.284-1.015A2 2 0 0 1 6 8.7V5.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5ZM8 6.65v1.58a1 1 0 0 0 .593.914l3 1.335a1 1 0 0 0 .814 0l3-1.335A1 1 0 0 0 16 8.23V6.65a.5.5 0 0 0-.703-.457l-2.484 1.105a2 2 0 0 1-1.626 0L8.703 6.193A.5.5 0 0 0 8 6.65Z", fillRule: "evenodd" }) }) })));
};
