var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const LabelOutline = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M6.25 4a.25.25 0 0 0-.25.25v12.922l4.232-4.233a2.5 2.5 0 0 1 3.536 0L18 17.172V4.25a.25.25 0 0 0-.25-.25H6.25ZM5.5 2h13A1.5 1.5 0 0 1 20 3.5v16.086c0 .89-1.077 1.337-1.707.707l-5.94-5.94a.5.5 0 0 0-.707 0l-5.939 5.94c-.63.63-1.707.184-1.707-.707V3.5A1.5 1.5 0 0 1 5.5 2Z", fillRule: "evenodd" }) }) })));
};
