import { Entur } from '@ruter-as/dpi-frontend';
import { getSecondsToDeparture } from './dates';
import { getETAText } from './eta';
import { createHiddenLineString } from './lines';

export const formatDepartures = (response: Entur.Result, boardsFilters: Mon.BoardConfig[]) => {
  const departures: Mon.Departure[][] = [];

  Object.entries(response).forEach(([_, values], index) => {
    const numberOfDepartures = boardsFilters[index].numberOfDepartures;
    const hiddenLines = boardsFilters[index].hiddenLines || {};
    const disabledTransportModes = boardsFilters[index].disabledTransportModes || [];
    const estimatedCalls = Array.isArray(values)
      ? values.map((d) => d.estimatedCalls).reduce((tail, current) => [...current, ...tail], [])
      : (values as Entur.Board).estimatedCalls;

    const formatedEstimatedCalls = formatEstimatedCalls(estimatedCalls);
    const filteredEstimatedCalls = filterEstimatedCalls(formatedEstimatedCalls, hiddenLines, disabledTransportModes);
    const sortedEstimatedCalls = sortDeparturesAsc(filteredEstimatedCalls).slice(0, numberOfDepartures);

    const emptyDeparturesCount = numberOfDepartures - sortedEstimatedCalls.length;
    const emptyDepartures = fillWithEmptyDepartures(emptyDeparturesCount);

    departures.push([...sortedEstimatedCalls, ...emptyDepartures]);
  });
  return departures;
};

export const sortDeparturesAsc = (estimatedCalls: Mon.Departure[]): Mon.Departure[] =>
  estimatedCalls.slice().sort((a, b) => new Date(a.departureTime).getTime() - new Date(b.departureTime).getTime());

export const filterEstimatedCalls = (
  estimatedCalls: Mon.Departure[],
  hiddenLines: any = {},
  disabledTransportModes: Mon.TransportMode[]
): Mon.Departure[] => {
  return estimatedCalls.filter(({ quayId, lineNumber, frontText, stopPlaceId, transportMode, parentStopPlaceId }) => {
    if (disabledTransportModes.includes(transportMode)) {
      return false;
    }

    const targetStopPlaceId = parentStopPlaceId || stopPlaceId;

    if (hiddenLines[targetStopPlaceId] && Array.isArray(hiddenLines[targetStopPlaceId])) {
      const hiddenLineString = createHiddenLineString(quayId, lineNumber, frontText);
      if (hiddenLines[targetStopPlaceId].includes(hiddenLineString)) {
        return false;
      }
    }
    return true;
  });
};

export const fillWithEmptyDepartures = (number = 0): Mon.Departure[] => {
  if (number < 0) {
    return [];
  }
  return new Array(number).fill({
    frontText: '',
    serviceJourneyId: '',
    stopPlaceId: '',
    parentStopPlaceId: '',
    lineNumber: '',
    eta: 0,
    etaText: '',
    realtime: '',
    departureTime: '',
    quayPublicCode: '',
    quayId: '',
    transportMode: '',
    isEmpty: true,
  });
};

export const formatEstimatedCalls = (estimatedCalls: Entur.EstimatedCall[] = []): Mon.Departure[] => {
  const duplicateServiceJourneyIdsAndQuays = new Set();
  const departures = estimatedCalls
    .map((ec) => {
      const serviceJourneyId = ec?.serviceJourney?.id ?? null;
      const quayId = ec?.quay?.id ?? '';
      const parentStopPlaceId = ec?.quay?.stopPlace?.parent?.id ?? '';
      const stopPlaceId = ec?.quay?.stopPlace?.id ?? '';
      const uniqueIdentifier = `${serviceJourneyId}-${quayId}`;

      if (duplicateServiceJourneyIdsAndQuays.has(uniqueIdentifier)) {
        return null;
      } else {
        duplicateServiceJourneyIdsAndQuays.add(uniqueIdentifier);
      }

      const departureTime = ec.actualDepartureTime || ec.expectedDepartureTime || ec.aimedDepartureTime;
      const eta = getSecondsToDeparture(departureTime);
      return {
        serviceJourneyId,
        stopPlaceId,
        parentStopPlaceId,
        lineNumber: ec?.serviceJourney?.line?.publicCode ?? '',
        frontText: ec?.destinationDisplay?.frontText ?? '',
        eta,
        etaText: getETAText(eta, departureTime, ec.realtime),
        situations: (ec?.situations || []) as Mon.Situation[],
        realtime: ec.realtime,
        departureTime,
        quayPublicCode: ec?.quay?.publicCode ?? '',
        quayId,
        transportMode: ec?.serviceJourney?.line?.transportMode ?? '',
        cancellation: ec?.cancellation,
      };
    })
    .filter((ec) => ec !== null);

  return departures as Mon.Departure[];
};
