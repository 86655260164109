var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const StarOutline = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "m12.5 3.236 2.33 4.658A2 2 0 0 0 16.617 9h4.247l-3.277 3.932a2 2 0 0 0-.36 1.913l1.922 5.767-5.563-3.6a2 2 0 0 0-2.173 0l-5.564 3.6 1.903-5.71a2 2 0 0 0-.483-2.046L3.414 9h4.968a2 2 0 0 0 1.789-1.106L12.5 3.236Z" }) }) })));
};
