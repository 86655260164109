var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Shield = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M12.814 2.861a1.202 1.202 0 0 0-1.628.001c-1.875 1.76-3.848 2.103-6.54 2.55A1.972 1.972 0 0 0 3 7.365v2.767a12 12 0 0 0 4.633 9.472l1.297 1.01a5 5 0 0 0 6.14 0l1.297-1.01A12 12 0 0 0 21 10.131V7.365c0-.968-.694-1.794-1.648-1.957-2.782-.476-4.719-.846-6.538-2.547Z" }) }) })));
};
