var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const DisabledPersonAlt = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M14 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" }), _jsx("path", { d: "M12.4 6.13A2.613 2.613 0 0 0 10.25 5H6.414a1 1 0 0 0-.707.293L3.914 7.086a.5.5 0 0 0 0 .707l1.414 1.414a.5.5 0 0 0 .708 0L7.243 8H9.7l-.378 2.146A6 6 0 1 0 14 16h2l2.508 4.71a.5.5 0 0 0 .665.212l1.8-.9a.5.5 0 0 0 .222-.671l-2.918-5.8a1 1 0 0 0-.893-.551h-4.187a6.01 6.01 0 0 0-.631-.893 1.104 1.104 0 0 1-.269-.892l.53-3.173A2.613 2.613 0 0 0 12.4 6.13ZM8 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z", fillRule: "evenodd" })] }) })));
};
