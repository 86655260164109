var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Flashlight = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M12.707 4.293a1 1 0 0 0 0 1.414l5.586 5.586a1 1 0 0 0 1.414 0l.586-.586a1 1 0 0 0 0-1.414l-5.586-5.586a1 1 0 0 0-1.414 0l-.586.586ZM3.414 16.586a2 2 0 0 0 0 2.828l1.172 1.172a2 2 0 0 0 2.828 0l6.293-6.293a1 1 0 0 1 .707-.293h2.172c.89 0 1.337-1.077.707-1.707l-5.586-5.586c-.63-.63-1.707-.184-1.707.707v2.172a1 1 0 0 1-.293.707l-6.293 6.293Z" }) }) })));
};
