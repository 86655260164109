var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Gift = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M9.5 7A2.5 2.5 0 1 1 12 4.5V7H9.5ZM15 7a2 2 0 1 0-2-2v2h2ZM3.5 12a.5.5 0 0 1-.5-.5V10a2 2 0 0 1 2-2h5.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-7Zm7.5 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0-.5.5V20a2 2 0 0 0 2 2h4.5a.5.5 0 0 0 .5-.5v-7Zm9 0a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5H18a2 2 0 0 0 2-2v-5.5Zm1-4.5a2 2 0 0 0-2-2h-5.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V10Z" }) }) })));
};
