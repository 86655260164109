import React from 'react';
import cx from 'classnames';
import { getIconWithoutCircle } from '../Icons';

import './styles.scss';

interface Props {
  title: string;
  transportModes: Mon.TransportMode[];
  useColumnLayout: boolean;
}

const BoardHeader = ({ title, transportModes = [], useColumnLayout }: Props) => {
  const icons = transportModes.map((tm) => getIconWithoutCircle(tm, 'icon', {}, tm));
  return (
    <div className={cx('boardHeader', { large: !useColumnLayout })}>
      {transportModes.length ? <div className="icons">{icons}</div> : null}
      <div className="title">{title}</div>
    </div>
  );
};

export default BoardHeader;
