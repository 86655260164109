const specialCityBusLines = ['100', '110', '300', '130', '130N', '140', '140N', '145'];

export const matchesRegion = (lineNumber: string) =>
  /^[0-9]{3,}/.test(lineNumber) && !specialCityBusLines.includes(lineNumber);

export const matchesCity = (lineNumber: string) =>
  specialCityBusLines.includes(lineNumber) || /^[0-9]{1,2}?[a-z|A-Z]?$/.test(lineNumber);

const getBusClass = (lineNumber: string) => {
  if (matchesCity(lineNumber)) {
    return 'bus bus-city';
  } else if (matchesRegion(lineNumber)) {
    return 'bus bus-region';
  } else {
    return 'bus bus-other';
  }
};

export const getTransportModeClass = (lineNumber: string, transportMode: Mon.TransportMode) => {
  switch (transportMode) {
    case 'bus':
      return getBusClass(lineNumber);
    case 'coach':
      return getBusClass(lineNumber);
    case 'metro':
      return 'metro metro-line';
    case 'tram':
      return 'tram';
    case 'water':
      return 'water';
    case 'rail':
      return 'rail';
    default:
      return '';
  }
};
