var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Bell = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M14 4c0 .096-.007.191-.02.284A7.003 7.003 0 0 1 19 11v1.172a2 2 0 0 0 .586 1.414l1.121 1.121a1 1 0 0 1 .293.707V16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.586a1 1 0 0 1 .293-.707l1.121-1.121A2 2 0 0 0 5 12.172V11a7.003 7.003 0 0 1 5.02-6.716A2 2 0 1 1 14 4Zm-2 18a3 3 0 0 1-2.959-2.502c-.045-.273.183-.498.459-.498h5c.276 0 .504.226.459.498A3 3 0 0 1 12 22Z" }) }) })));
};
