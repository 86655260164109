var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Zone = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M19.342 15.182a.526.526 0 0 0 .233.679l.891.453a.48.48 0 0 0 .661-.224 10.06 10.06 0 0 0 .862-3.591.481.481 0 0 0-.487-.5l-1-.001a.526.526 0 0 0-.516.499 8.064 8.064 0 0 1-.644 2.685Zm.098-6.124a.527.527 0 0 0 .646.316l.95-.31a.481.481 0 0 0 .31-.626 10.056 10.056 0 0 0-1.93-3.148.481.481 0 0 0-.698-.01l-.708.707a.525.525 0 0 0-.012.718 8.057 8.057 0 0 1 1.443 2.353Zm-4.258-4.4c.254.11.554.013.68-.233l.453-.892a.481.481 0 0 0-.225-.66 10.059 10.059 0 0 0-3.59-.863.481.481 0 0 0-.5.487l-.002 1c0 .276.224.499.5.516a8.062 8.062 0 0 1 2.684.645ZM9.06 4.559a.525.525 0 0 0 .314-.645l-.31-.951a.481.481 0 0 0-.626-.309 10.056 10.056 0 0 0-3.148 1.93.481.481 0 0 0-.009.697l.707.708c.195.195.51.194.717.012A8.056 8.056 0 0 1 9.06 4.559ZM2.276 9.665c.148-.614.349-1.2.597-1.756a.481.481 0 0 1 .66-.224l.892.453a.526.526 0 0 1 .233.68 8.065 8.065 0 0 0-.644 2.684.526.526 0 0 1-.516.499h-1a.481.481 0 0 1-.487-.5c.03-.609.118-1.223.265-1.836Zm12.665 9.775a.525.525 0 0 0-.315.645l.31.95a.481.481 0 0 0 .626.31 10.054 10.054 0 0 0 3.148-1.93.481.481 0 0 0 .009-.698l-.706-.707a.525.525 0 0 0-.718-.012 8.056 8.056 0 0 1-2.354 1.442Zm-11.37-5.498c-.8.32-.847 1.437-.075 1.823l3.366 1.684a1 1 0 0 1 .448.447l1.683 3.367c.386.771 1.503.725 1.823-.076l3.963-9.908c.327-.816-.483-1.627-1.3-1.3l-9.908 3.963Z" }) }) })));
};
