var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Bike = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M19.158 5.474a.5.5 0 0 1-.316.633l-1.375.458a.25.25 0 0 0-.16.31l1.538 5.128a4 4 0 1 1-1.916.574l-.252-.84L12 16.414a2 2 0 0 1-1.414.586H9.068a.26.26 0 0 0-.247.187A4.002 4.002 0 0 1 1 16a4 4 0 0 1 3.475-3.966.26.26 0 0 0 .15-.073l2.698-2.698a.25.25 0 0 0 .046-.288l-.918-1.837A.25.25 0 0 0 6.227 7H5.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-.477a.25.25 0 0 0-.224.362l.25.5A.25.25 0 0 0 9.273 8h6.283l-.514-1.713a1 1 0 0 1 .642-1.236l2.525-.842a.5.5 0 0 1 .633.317l.316.948ZM14.982 10a.25.25 0 0 1 .177.427L12.186 13.4a.25.25 0 0 1-.4-.065l-1.487-2.973a.25.25 0 0 1 .224-.362h4.46Zm-7.774 2.664c-.14-.092-.168-.29-.05-.408l.99-.99a.25.25 0 0 1 .4.066l1.653 3.306a.25.25 0 0 1-.223.362h-.91a.26.26 0 0 1-.247-.186 4.01 4.01 0 0 0-1.613-2.15ZM5 15.5a.5.5 0 0 1 .5-.5h.8c.192 0 .307-.207.177-.349a2 2 0 1 0 0 2.698c.13-.142.014-.349-.178-.349H5.5a.5.5 0 0 1-.5-.5v-1Zm12.669-.457c-.055-.184-.286-.235-.385-.07a2 2 0 1 0 2.583-.776c-.173-.083-.338.086-.283.27l.23.767a.5.5 0 0 1-.335.622l-.958.288a.5.5 0 0 1-.623-.336l-.23-.765Z", fillRule: "evenodd" }) }) })));
};
