var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Lightbulb = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M16 15.767c0-.327.163-.63.416-.836a7 7 0 1 0-8.833 0c.254.207.417.51.417.836V17.5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-1.733ZM8 20.25a.25.25 0 0 1 .25-.25h7.5a.25.25 0 0 1 .25.25v.25a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-.25Zm8.993-10.476a.241.241 0 0 1-.244.226h-.996a.266.266 0 0 1-.26-.287A3.5 3.5 0 0 0 12.25 6.01a.265.265 0 0 1-.249-.259v-1a.24.24 0 0 1 .25-.244 5 5 0 0 1 4.743 5.268Z", fillRule: "evenodd" }) }) })));
};
