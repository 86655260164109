var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Target = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" }), _jsx("path", { d: "M13 2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v2.137a.527.527 0 0 1-.427.509 7.009 7.009 0 0 0-5.427 5.427.527.527 0 0 1-.509.427H2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2.137c.25 0 .458.184.509.427a7.009 7.009 0 0 0 5.427 5.427c.243.05.427.26.427.509V21.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-2.137c0-.25.184-.458.427-.509a7.009 7.009 0 0 0 5.427-5.427.527.527 0 0 1 .509-.427H21.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2.137a.527.527 0 0 1-.509-.427 7.009 7.009 0 0 0-5.427-5.427.527.527 0 0 1-.427-.509V2.5Zm4 9.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z", fillRule: "evenodd" })] }) })));
};
