var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const CogWheel = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "M14.19 2.757A1 1 0 0 0 13.22 2h-2.44a1 1 0 0 0-.97.757L9.524 3.91a1.078 1.078 0 0 1-.638.72 7.996 7.996 0 0 0-1.709.988c-.27.205-.617.286-.942.193L5.09 5.483a1 1 0 0 0-1.14.461L2.73 8.056a1 1 0 0 0 .171 1.219l.855.826c.242.235.346.576.304.911a8.076 8.076 0 0 0 0 1.976c.042.335-.062.677-.304.911l-.855.826a1 1 0 0 0-.17 1.22l1.218 2.11a1 1 0 0 0 1.142.462l1.143-.327c.325-.093.673-.012.942.193a7.975 7.975 0 0 0 1.71.988c.31.132.555.392.637.72l.288 1.151a1 1 0 0 0 .97.758h2.438a1 1 0 0 0 .97-.758l.288-1.151c.082-.328.326-.588.638-.72a7.994 7.994 0 0 0 1.709-.988c.27-.204.617-.286.942-.193l1.144.327a1 1 0 0 0 1.14-.461l1.22-2.112a1 1 0 0 0-.17-1.219l-.856-.826a1.078 1.078 0 0 1-.304-.911 8.072 8.072 0 0 0 0-1.976 1.078 1.078 0 0 1 .304-.91l.855-.827a1 1 0 0 0 .17-1.22l-1.218-2.11a1 1 0 0 0-1.141-.462l-1.144.327a1.077 1.077 0 0 1-.942-.193 7.995 7.995 0 0 0-1.709-.988 1.078 1.078 0 0 1-.638-.72l-.288-1.152ZM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z", fillRule: "evenodd" }) }) })));
};
