var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const MultipleTickets = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M2.936 8.293c-.238-.238-.477-.357-.835-.477-.477-.119-.954-.596-.954-.954v-.954C1.147 4.835 1.982 4 3.055 4h14.908c1.073 0 1.908.835 1.908 1.789H5.918c-1.431 0-2.743 1.073-2.982 2.504Zm2.744 5.128c0 .716-.478 1.312-1.074 1.55-.954.359-1.55 1.193-1.67 2.028-1.073-.119-1.789-.954-1.789-1.908v-.954c0-.477.477-.954.954-1.073a3.768 3.768 0 0 0 1.67-1.67c.239.239.477.358.835.477.596.239 1.073.835 1.073 1.55Z" }), _jsx("path", { d: "M20.945 6.862c1.073 0 1.908.835 1.908 1.908v1.074c0 .477-.477.954-.955 1.073-1.073.358-1.908 1.431-1.908 2.624 0 1.192.835 2.266 1.909 2.624.477.119.954.596.954 1.073v.954a1.884 1.884 0 0 1-1.909 1.908H5.919a1.884 1.884 0 0 1-1.908-1.908v-.954c0-.477.477-.954.954-1.073 1.073-.358 1.908-1.55 1.908-2.743 0-1.193-.835-2.266-1.908-2.624-.477-.12-.954-.596-.954-1.073V8.77c0-1.073.834-1.908 1.908-1.908h15.027Zm-9.9 4.77c.24 0 .478.24.478.478v2.862c0 .239-.239.477-.477.477h-.954c-.239 0-.477-.239-.477-.477V12.11c0-.239.238-.477.477-.477h.954Zm-.834-2.862c-.238 0-.477.239-.477.478v.954c0 .238.239.477.477.477h.954c.239 0 .477-.239.477-.477v-.954c0-.239-.238-.478-.477-.478h-.954Zm.835 7.514c.238 0 .477.238.477.477v.954c0 .238-.239.477-.477.477h-.954c-.239 0-.477-.239-.477-.477v-.954c0-.239.238-.477.477-.477h.954Z", fillRule: "evenodd" })] }) })));
};
