import {produce} from 'immer';
import { getDefaultBoardSettings } from './URL';
import { createHiddenLineString } from './lines';
import { Entur } from '@ruter-as/dpi-frontend';

export const updateNumberOfDepartures = (boards: Mon.BoardConfig[], index: number, numberOfDepartures: number) => {
  return produce(boards, (draftBoards) => {
    if (!draftBoards[index]) {
      draftBoards.push(getDefaultBoardSettings('Ny tavle'));
    }
    draftBoards[index].numberOfDepartures = Number(numberOfDepartures);
  });
};

export const updateDelayedStartTime = (boards: Mon.BoardConfig[], index: number, numberOfDepartures: number) => {
  return produce(boards, (draftBoards) => {
    if (!draftBoards[index]) {
      draftBoards.push(getDefaultBoardSettings('Ny tavle'));
    }
    draftBoards[index].delayedStartTime = Number(numberOfDepartures);
  });
};

export const updateToggleEnableLine = (
  boards: Mon.BoardConfig[],
  index: number,
  stopPlaceId: string,
  lineId: string,
  checked: boolean
) => {
  return produce(boards, (draftBoards) => {
    if (!draftBoards[index]) {
      draftBoards.push(getDefaultBoardSettings('Ny tavle'));
    }

    if (!draftBoards[index].hiddenLines[stopPlaceId]) {
      draftBoards[index].hiddenLines[stopPlaceId] = [];
    }

    if (!checked) {
      draftBoards[index].hiddenLines[stopPlaceId].push(lineId);
    } else {
      draftBoards[index].hiddenLines[stopPlaceId] = draftBoards[index].hiddenLines[stopPlaceId].filter(
        (hiddenId) => hiddenId !== lineId
      );
    }
  });
};

export const updateEnabledBoard = (boards: Mon.BoardConfig[], index: number, value: boolean) => {
  return produce(boards, (draftBoards) => {
    draftBoards[index].enabled = value;
  });
};

export const updateHideTrackColumn = (boards: Mon.BoardConfig[], index: number, value: boolean) => {
  return produce(boards, (draftBoards) => {
    draftBoards[index].hideTrackColumn = value;
  });
};

export const updateStopPlaceIds = (boards: Mon.BoardConfig[], index: number, stopPlaceIds: string[]) => {
  return produce(boards, (draftBoards) => {
    if (!draftBoards[index]) {
      draftBoards.push(getDefaultBoardSettings('Ny tavle'));
    }
    const existingStopPlaceIds = draftBoards[index].stopPlaceIds;
    const newStopPlaceIds = stopPlaceIds.filter((stopPlaceId) => !existingStopPlaceIds.includes(stopPlaceId));
    draftBoards[index].stopPlaceIds = [...newStopPlaceIds, ...existingStopPlaceIds];
  });
};

export const removeStopPlaceId = (boards: Mon.BoardConfig[], index: number, stopPlaceId: string) => {
  return produce(boards, (draftBoards) => {
    const stopPlaceIds = draftBoards[index].stopPlaceIds || [];
    draftBoards[index].stopPlaceIds = stopPlaceIds.filter((id) => id !== stopPlaceId);
  });
};

export const updateWhitelistedTransportModes = (
  boards: Mon.BoardConfig[],
  index: number,
  transportMode: Mon.TransportMode,
  enabled: boolean
) => {
  return produce(boards, (draftBoards) => {
    if (!draftBoards[index]) {
      draftBoards.push(getDefaultBoardSettings('Ny tavle'));
    }

    if (!Array.isArray(draftBoards[index].disabledTransportModes)) {
      draftBoards[index].disabledTransportModes = [];
    }

    if (enabled) {
      draftBoards[index].disabledTransportModes = draftBoards[index].disabledTransportModes.filter(
        (_transportMode) => transportMode !== _transportMode
      );
    } else {
      draftBoards[index].disabledTransportModes.push(transportMode);
    }
  });
};

export const updateToggleAllLines = (
  boards: Mon.BoardConfig[],
  index: number,
  stopPlaceId: string,
  checked: boolean,
  allLines: Entur.LocationLine[] = []
) => {
  return produce(boards, (draftBoards) => {
    if (!draftBoards[index].hiddenLines[stopPlaceId]) {
      draftBoards[index].hiddenLines[stopPlaceId] = [];
    }

    if (checked) {
      draftBoards[index].hiddenLines[stopPlaceId] = [];
    } else {
      draftBoards[index].hiddenLines[stopPlaceId] = allLines.map((line) =>
        createHiddenLineString(line.quay.id, line.publicCode, line.frontText)
      );
    }
  });
};

export const updateBoardTitle = (boards: Mon.BoardConfig[], index: number, title: string) => {
  return produce(boards, (draftBoards) => {
    if (!draftBoards[index]) {
      draftBoards.push(getDefaultBoardSettings('Ny tavle'));
    }
    draftBoards[index].title = title;
  });
};

const isBoardEnabled = (value?: boolean) => (typeof value === 'boolean' ? value : true);

export const getFilteredBoardsConfig = (boardsConfig: Mon.BoardConfig[] = []) =>
  Array.isArray(boardsConfig)
    ? boardsConfig.filter(
        (config) => config.stopPlaceIds.length && config.numberOfDepartures > 0 && isBoardEnabled(config.enabled)
      )
    : [];

export const FILTERED_BOARD_REASONS: Mon.BoardReason = Object.freeze({
  NO_BOARDS: 'NO BOARDS',
  ZERO_NoD: 'ZERO_NoD',
  ZERO_STOP_PLACES: 'ZERO_STOP_PLACES',
  BOARD_DISABLED: 'BOARD_DISABLED',
});

type FilteredBoardsVerbose = {
  boards: Mon.BoardConfig[];
  reasons: string[][] | null;
};

export const getFilteredBoardsVerbose = (boardsConfig: Mon.BoardConfig[] = []): FilteredBoardsVerbose => {
  if (!boardsConfig.length) {
    return {
      boards: [],
      reasons: null,
    };
  }
  const reasons: string[][] = [];
  const filteredBoards = boardsConfig.filter((config) => {
    const reason: string[] = [];
    let filtered = true;

    if (!isBoardEnabled(config.enabled)) {
      filtered = false;
      reason.push(FILTERED_BOARD_REASONS.BOARD_DISABLED);
    }

    if (!config.stopPlaceIds.length) {
      filtered = false;
      reason.push(FILTERED_BOARD_REASONS.ZERO_STOP_PLACES);
    }

    if (config.numberOfDepartures < 0) {
      filtered = false;
      reason.push(FILTERED_BOARD_REASONS.ZERO_NoD);
    }
    reasons.push(reason);
    return filtered;
  });

  return {
    boards: filteredBoards,
    reasons,
  };
};
