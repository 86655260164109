var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Home = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M13 5a2 2 0 0 1-1.031 1.75A1.003 1.003 0 0 1 11 8a1 1 0 0 1-.969-1.25A2 2 0 1 1 13 5Z" }), _jsx("path", { d: "M20 10c0 .234-.04.458-.114.667.68.55 1.114 1.39 1.114 2.333 0 .546-.146 1.059-.401 1.5a3 3 0 0 1-1.273 4.192.559.559 0 0 0-.326.49V20h2.75a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25H2.25a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25H3v-5.172a2 2 0 0 1 .586-1.414l3.707-3.707a1 1 0 0 1 1.414 0L10 11v-.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2.293a.5.5 0 0 0 .146.353l.268.268A2 2 0 0 1 13 14.828V20h4v-.817a.559.559 0 0 0-.326-.491 3 3 0 0 1-1.273-4.192A2.987 2.987 0 0 1 15 13c0-.942.434-1.783 1.114-2.333A2 2 0 1 1 20 10Zm-9 10v-2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V20h2Zm-6-5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Z", fillRule: "evenodd" })] }) })));
};
