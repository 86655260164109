var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Cross = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { d: "m12 13.414 5.44 5.44a.5.5 0 0 0 .706 0l.708-.708a.5.5 0 0 0 0-.707L13.414 12l5.44-5.44a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L12 10.586l-5.44-5.44a.5.5 0 0 0-.706 0l-.708.708a.5.5 0 0 0 0 .707L10.586 12l-5.44 5.44a.5.5 0 0 0 0 .706l.708.708a.5.5 0 0 0 .707 0L12 13.414Z" }) }) })));
};
