var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const StoppingPlace = (props) => {
    const { className } = props, otherProps = __rest(props, ["className"]);
    const classes = [className, 'rds-icon'].filter(Boolean).join(' ');
    return (_jsx("svg", Object.assign({ viewBox: "0 0 24 24" }, otherProps, { className: classes, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M7.5 6.875C7.5 5.839 8.34 5 9.375 5h11.25c1.035 0 1.875.84 1.875 1.875v10c0 1.035-.84 1.875-1.875 1.875v.625c0 .345-.28.625-.625.625h-1.875a.625.625 0 0 1-.625-.625v-.625h-5v.625c0 .345-.28.625-.625.625H10a.625.625 0 0 1-.625-.625v-.625A1.875 1.875 0 0 1 7.5 16.875v-10Zm2.572 5.866a1.179 1.179 0 0 1-.697-1.094v-4.46c0-.172.14-.312.313-.312h10.624c.173 0 .313.14.313.313v4.459c0 .471-.264.908-.697 1.094A12.46 12.46 0 0 1 15 13.75c-1.75 0-3.416-.36-4.928-1.009Zm.24 4.384a1.188 1.188 0 1 0 0-2.375 1.188 1.188 0 0 0 0 2.375Zm10.563-1.188a1.188 1.188 0 1 1-2.375 0 1.188 1.188 0 0 1 2.375 0Z", fillRule: "evenodd" }), _jsx("path", { d: "M2 5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h.75v6.5h-.57a.25.25 0 0 0-.237.17l-.333 1a.25.25 0 0 0 .237.33h3.306a.25.25 0 0 0 .237-.33l-.333-1a.25.25 0 0 0-.237-.17h-.57V12H5a.5.5 0 0 0 .5-.5v-6A.5.5 0 0 0 5 5H2Z" })] }) })));
};
