import React from 'react';
import { getIconWithoutCircle } from '../../shared/components/Icons';

import './styles.scss';

const peliasCategoryMap = {
  onstreetBus: 'bus',
  busStation: 'bus',
  onstreetTram: 'tram',
  railStation: 'rail',
  metroStation: 'metro',
  harbourPort: 'water',
  ferryPort: 'water',
  ferryStop: 'water',
  street: 'home',
  'street address': 'home',
  Places: 'home',
  bydel: 'home',
  tettsted: 'home',
  vegadresse: 'home',
  poi: 'poi',
  tettsteddel: 'home',
};

const CategoriesPanel = ({ categories = [] }) => {
  const uniqueCategories = Array.from(new Set(categories));
  const icons = uniqueCategories.map((tm) => {
    const peliasSubtype = peliasCategoryMap[tm];
    if (peliasSubtype) {
      return getIconWithoutCircle(
        peliasSubtype,
        '',
        {
          fill: '#000',
          height: '1.5em',
          marginLeft: 5,
        },
        tm
      );
    }
    return null;
  });
  return <div className="categoriesPanel">{icons}</div>;
};

export default CategoriesPanel;
